import { FormEvent, useMemo, useRef, useState, useEffect } from 'react';
import { TextField, Button, Tabs, Tab } from '@mui/material'
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import AG_GRID_LOCALE_ES from '../../assets/localeText/AG_GRID_LOCALE_ES';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalking } from '@fortawesome/free-solid-svg-icons';
import { iLREvento, iResponseSimple } from '../../iType';
import { URL_API, folderDefault } from '../../config';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import * as XLSX from 'xlsx';

const AsistenciaGlobal = () => {
  const gridRef = useRef<AgGridReact>(null);

  const { state } = useLocation();
  const navigate = useNavigate();

  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const [rowData, setRowData] = useState<iLREvento[]>([]);

  const [formValues, setFormValues] = useState<{
    dni: string,
    typeAsistencia: number
  }>({
    dni: '',
    typeAsistencia: 0
  });

  const columnDefs: ColDef[] =
    [
      {
        headerName: 'N°',
        valueGetter: 'node.rowIndex + 1',
        minWidth: 60,
        sortable: false,
        filter: false,
        headerCheckboxSelection: false,
        checkboxSelection: false,
        pinned: 'left',
        width: 60,
      },
      { field: "DOCUMENTO", headerName: 'DNI', minWidth: 100 },
      {
        headerName: 'NOMBRES', minWidth: 300,
        cellRenderer: (info: any) => {
          const { data } = info;
          return `${data.APPATERNO} ${data.APMATERNO} ${data.NOMBRES}`.replace(' ', ' ')
        }
      },
      {
        field: "REGISTRO_INGRESO", headerName: 'F. INICIO', minWidth: 150,
        cellRenderer: (info: any) => {
          return info.value ? dayjs(info.value).format("DD/MM/YYYY hh:mm A") : "-";
        }
      },
      {
        field: "REGISTRO_SALIDA", headerName: 'F. FINAL', minWidth: 150,
        cellRenderer: (info: any) => {
          return info.value ? dayjs(info.value).format("DD/MM/YYYY hh:mm A") : "-";
        }
      },
    ];

  const tabsList = [
    {
      title: "ENTRADA",
      icon: <FontAwesomeIcon icon={faPersonWalking} />,
      value: "entrada"
    },
    {
      title: "SALIDA",
      icon: <FontAwesomeIcon icon={faPersonWalking} />,
      value: "salida"
    }
  ]

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      // minWidth: 180,
      filter: true,
      // floatingFilter: true,
      suppressSizeToFit: false,
      sortable: true,
      resizable: true,
      editable: false,
      cellStyle: { textAlign: 'center', verticalAlign: 'middle' }
    };
  }, []);

  const rowClassRules = useMemo(() => {
    return {
      // row style function
      'row-deleted': (params: any) => {
        return params.data.ESTADO === "0";
      },
    };
  }, []);

  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_ES;
  }, []);

  const handleTabChange = (
    _: React.SyntheticEvent,
    tAsiste: number,
  ) => {
    setFormValues({ ...formValues, typeAsistencia: tAsiste });
  };

  const OnSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    Asistencia();
  }

  const handleInputChange = (event: React.ChangeEvent<any>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const ListRegistroAsistencia = () => {
    if (state) {
      fetch(`${URL_API}/guest/assistants`, {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ ID_EVENTO: state.ID_EVENTO, ID_GCODE: state.ID_GCODE })
      })
        .then((data) => data.ok && data.json())
        .then((data: iLREvento[]) => {
          setRowData(data);
        })
    }
  };

  const Asistencia = () => {
    if (formValues.dni.length === 8) {
      fetch(`${URL_API}/guest/asistencia`, {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          T_INGRESO: formValues.typeAsistencia || "0",
          ID_EVENTO: state.ID_EVENTO,
          ID_GCODE: state.ID_GCODE,
          DOCUMENTO: formValues.dni
        })
      })
        .then((data) => data.ok && data.json())
        .then((data: iResponseSimple[]) => {
          ListRegistroAsistencia();

          setFormValues({
            ...formValues,
            dni: ''
          });

          textFieldRef.current?.focus();

          const Toast = Swal.mixin({
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });

          if (data.length) {
            const { ICON, MESSAGE_TEXT } = data[0]
            Toast.fire({
              icon: ICON as SweetAlertIcon,
              title: MESSAGE_TEXT
            })
          }
        })
    }
  }

  const exportData = () => {
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      const newData: {
        DNI: string,
        APELLIDO_PATERNO: string,
        APELLIDO_MATERNO: string,
        NOMBRES: string,
        F_INICIO: string,
        F_FINAL: string,
        CONDICION: string
      }[] = rowData.map((i) => ({
        DNI: i.DOCUMENTO,
        APELLIDO_PATERNO: i.APPATERNO,
        APELLIDO_MATERNO: i.APMATERNO,
        NOMBRES: i.NOMBRES,
        F_INICIO: i.REGISTRO_INGRESO,
        F_FINAL: i.REGISTRO_SALIDA,
        CONDICION: 'ASISTENTE'
      }));

      const ws = XLSX.utils.json_to_sheet(newData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "ASISTENCIA");
      XLSX.writeFile(wb, `ASISTENCIA_${state.ID_EVENTO}_${state.ID_GCODE}_${state.NOMBRE_EVENTO.replace(" ", "_")}.xlsx`);
    }
  };


  useEffect(() => {
    if (state) ListRegistroAsistencia()
    else navigate(`${folderDefault}/event/myevents`)
  }, [])

  return (
    <div id="asistencia_global">
      <form method="post" onSubmit={OnSubmit}>
        <h3>Registro de asistencia</h3>
        <div className="type-asistente">
          <Tabs value={formValues.typeAsistencia} onChange={handleTabChange} aria-label="icon label tabs">
            {
              tabsList.map((item, index) =>
                <Tab key={index} icon={item.icon} label={item.title} />
              )
            }
          </Tabs>
        </div>
        <TextField
          label="DNI"
          inputRef={textFieldRef}
          variant="outlined"
          name='dni'
          value={formValues.dni}
          onChange={handleInputChange}
          autoFocus />

        <Button variant="contained" type='submit'>Registrar</Button>
        <Button variant='text' color='success' type='button' onClick={exportData}>EXPORTAR A EXCEL</Button>
      </form>
      <div className="listado">
        <div className="ag-theme-alpine" >
          <AgGridReact
            className="tableEvents"
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            animateRows={true}
            rowClassRules={rowClassRules}
            suppressRowClickSelection={true}
            suppressAggFuncInHeader={true}
            pagination={true}
            paginationAutoPageSize={true}
            paginateChildRows={true}
            floatingFiltersHeight={50}
            defaultColDef={defaultColDef}
            localeText={localeText}
            enableCellTextSelection={true}
          />
        </div>
      </div>
    </div>
  )
}

export default AsistenciaGlobal;