import './App.sass';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { Routes, Route, Navigate } from 'react-router-dom';
import CreateEvent from './Screens/Evento/CreateEvent';
import ListEvent from './Screens/Evento/ListEvent';
import RegisterPerson from './Screens/Registro/RegisterPerson';
import Login from './Screens/Login';
import Error from './Screens/error/Error';
import CreateUser from './Screens/Usuario/CreateUser';
import DrawerContent from './Screens/DrawerContent';
import { folderDefault } from './config';
import PrivateRoute from './Screens/PrivateRoute';
import MyEvents from './Screens/Evento/MyEvents';
import ListUser from './Screens/Usuario/ListUser';
import PaisCreador from './Screens/Mantenimiento/PaisCreador';
import TEventoCreador from './Screens/Mantenimiento/TEventoCreador';
import ListRegistro from './Screens/Listado/ListRegistro';
import GenCode from './Screens/Asistencia/GenCode';
import ListRegistroAsistencia from './Screens/Listado/ListRegistroAsistencia';
import 'dayjs/locale/es' // carga bajo demanda
import AsistenciaGlobal from './Screens/Asistencia/AsistenciaGlobal';

function App() {

  useEffect(() => {
    dayjs.locale('es') // usar la configuración regional española globalmente
  }, [])

  return (
    <div id="App">
      <Routes>
        <Route path={`${folderDefault}/`} errorElement={<Navigate to={`${folderDefault}/error`} />}>
          <Route path={`${folderDefault}/`} element={<Navigate to={`${folderDefault}/event/list`} />}></Route>

          <Route path={`${folderDefault}/login`} element={<Login />}></Route>
          <Route path={`${folderDefault}/user/create`} element={
            <PrivateRoute onlyAdmin={true} element={
              <DrawerContent element={
                <CreateUser />} title='Registro de usuario' />}
            />
          }>
          </Route>
          <Route path={`${folderDefault}/user/list`} element={
            <PrivateRoute onlyAdmin={true} element={
              <DrawerContent element={
                <ListUser />} title='Lista de usuarios' />}
            />
          }>
          </Route>

          <Route path={`${folderDefault}/event/list`} element={<DrawerContent element={<ListEvent />} title='Eventos' />}></Route>
          <Route path={`${folderDefault}/event/myevents`} element={
            <PrivateRoute element={
              <DrawerContent element={<MyEvents />} title='Mis eventos' />} />
          }></Route>
          <Route path={`${folderDefault}/event/listRegistro`} element={<DrawerContent element={<ListRegistro />} title='Registro' />}></Route>
          <Route path={`${folderDefault}/event/create`} element={<DrawerContent element={<CreateEvent />} title='Crear evento' />}></Route>
          <Route path={`${folderDefault}/event/register/:evento`} element={<DrawerContent element={<RegisterPerson />} title='Registrarse' />}></Route>
          <Route path={`${folderDefault}/event/gcode`} element={<DrawerContent element={<GenCode />} title='Generar código de asistencia' />}></Route>
          {/* <Route path={`${folderDefault}/event/asistencia/:evento`} element={<DrawerContent element={<MarcarAsistencia />} title='Asistencia' />}></Route> */}
          <Route path={`${folderDefault}/event/asistencias/:evento`} element={<DrawerContent element={<AsistenciaGlobal />} title='Asistencia' />}></Route>

          <Route path={`${folderDefault}/asistencia/lista`} element={<DrawerContent element={<ListRegistroAsistencia />} title='Listado de asistencia' />}></Route>

          <Route path={`${folderDefault}/maintenance/tevento`} element={<DrawerContent element={<TEventoCreador />} title='Tipo de evento' />}></Route>
          <Route path={`${folderDefault}/maintenance/country`} element={<DrawerContent element={<PaisCreador />} title='País' />}></Route>

          <Route path={`${folderDefault}/error`} element={<Error />}></Route>
          <Route path={`${folderDefault}/*`} element={<Error />}></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
