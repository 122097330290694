
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, CardMedia, FormControl, InputAdornment, TextField } from "@mui/material";
import Container from "@mui/material/Container";
import { URL_API, folderDefault } from "../../config";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/es"; // Importa el complemento de idioma español
import { Link } from "react-router-dom";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { allowPermission, getInfoUser } from '../../Tools/Auth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import SearchIcon from '@mui/icons-material/Search';

import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import ViewEvento from "../../Emergentes/ViewEvento";
import { useNavigate } from "react-router-dom";

interface iListEvent {
  ID_EVENTO: string;
  FONDO: string;
  NOMBRE_EVENTO: string;
  NOMBRE_TIPOEVENTO: string;
  PONENTE: string;
  FINICIO: string;
  FFINAL: string;
  RESOLUCION: string;
  ORGANIZADOR: string;
  REALIZADOEN: string;
  ENLACE: string;
  ID_USUARIO: string,
}

dayjs.locale("es");

export default function ListEvent() {
  const [eventos, setEventos] = useState<iListEvent[]>([]);
  const [busqueda, setBusqueda] = useState("");

  const navigate = useNavigate();

  const obtenerEventos = () => {
    fetch(`${URL_API}/evento/list`, {
      method: "GET",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data: iListEvent[]) => {
        setEventos(data);
      });
  };

  useEffect(() => {
    obtenerEventos();
  }, []);

  return (
    <Container maxWidth={false} id="list-events">
      <FormControl fullWidth>
        <TextField
          label="Busqueda"
          placeholder="Ingrese nombre del evento a buscar"
          value={busqueda}
          onChange={(e) => setBusqueda(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </FormControl>
      <div className="boxes-events">
        {
          eventos.length > 0 ? (<ImageList variant="masonry" className="list-events-array">
            {eventos.filter((item => item.NOMBRE_EVENTO.toLowerCase().includes(busqueda.toLowerCase()))).map((item, index) => (
              <ImageListItem key={index} className="list-item">
                <Card>
                  <CardActionArea onClick={() => {
                    const MySwal = withReactContent(Swal);

                    MySwal.fire({
                      showConfirmButton: false,
                      showCloseButton: true,
                      showCancelButton: true,
                      cancelButtonText: 'Salir',
                      title: `
                    `,
                      html:
                        <>
                          <ViewEvento item={item}
                            onDirect={(response) => {
                              MySwal.close();

                              navigate(response.url,
                                response.state);
                            }}
                          />
                        </>
                      ,
                      backdrop: true,
                      allowOutsideClick: true,
                      customClass: {
                        container: 'my-swal-container'
                      },
                    });
                  }}>
                    {
                      item.FONDO ?
                        <CardMedia
                          component="img"
                          height="150"
                          image={item.FONDO}
                          alt={item.NOMBRE_EVENTO}
                        />
                        :
                        <FontAwesomeIcon icon={faBan} size="5x" />
                    }
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="p" className="title-event">
                        {item.NOMBRE_EVENTO}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {"Día: "}
                        <span>
                          {dayjs(item.FINICIO).format(
                            "dddd DD [de] MMMM [de] YYYY"
                          )}
                        </span>
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Hora: <span>{dayjs(item.FINICIO).format("hh:mm A")}</span>
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {"A realizarse en: "}
                        <span>{`${item.REALIZADOEN}`}</span>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      <Link
                        to={`${folderDefault}/event/register/${item.ID_EVENTO}`}
                        style={{ textDecoration: "none", color: "green" }}
                      >
                        REGISTRARSE
                      </Link>
                    </Button>
                    {/* <Button size="small" color="primary">
                      <Link
                        to={`${folderDefault}/event/asistencia/${item.ID_EVENTO}`}
                        style={{ textDecoration: "none", color: "green" }}
                      >
                        ASISTENCIA
                      </Link>
                    </Button> */}
                    {
                      (allowPermission(["ADMINISTRADOR"]) ||
                        item.ID_USUARIO === getInfoUser()?.ID_USUARIO) &&
                      <Button size="small" color="primary">
                        <Link
                          to={`${folderDefault}/event/listRegistro`}
                          style={{ textDecoration: "none", color: "green" }}
                          state={{
                            ID_EVENTO: item.ID_EVENTO,
                            NOMBRE_EVENTO: item.NOMBRE_EVENTO,
                            FINICIO: dayjs(item.FINICIO).format("DD/MM/YYYY hh:mm A")
                          }}
                        >
                          LISTA
                        </Link>
                      </Button>
                    }
                  </CardActions>
                </Card>
              </ImageListItem>
            ))}
          </ImageList>) : <h3>No hay eventos en este momento</h3>
        }

      </div>
    </Container>
  );
}