import { faBriefcase, faGlobe, faPerson } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const list_genero = [
  { code: "1", label: "FEMENINO" },
  { code: "2", label: "MASCULINO" },
  { code: "3", label: "OTRO" },
];

export const list_mContrato = [
  { code: "1", label: "D.L. 276" },
  { code: "2", label: "D.L. 728" },
  { code: "3", label: "D.L. 1057 - CAS" },
];

export const list_tCargo = [
  { code: "1", label: "JURISDICCIONAL" },
  { code: "2", label: "ADMINISTRATIVO" },
];

export const tabsList = [
  {
    title: "PERUANO",
    icon: <FontAwesomeIcon icon={faPerson} />,
    value: "invitado"
  },
  {
    title: "PERSONAL PJ",
    icon: <FontAwesomeIcon icon={faBriefcase} />,
    value: "personal"
  },
  {
    title: "EXTRANJERO",
    icon: <FontAwesomeIcon icon={faGlobe} />,
    value: "extranjero"
  },
]

export const corteList =
  [
    "CORTE SUPERIOR DE JUSTICIA DE AMAZONAS",
    "CORTE SUPERIOR DE JUSTICIA DE ANCASH",
    "CORTE SUPERIOR DE JUSTICIA DE APURÍMAC",
    "CORTE SUPERIOR DE JUSTICIA DE AREQUIPA",
    "CORTE SUPERIOR DE JUSTICIA DE AYACUCHO",
    "CORTE SUPERIOR DE JUSTICIA DE CAJAMARCA",
    "CORTE SUPERIOR DE JUSTICIA DE CALLAO",
    "CORTE SUPERIOR DE JUSTICIA DE CAÑETE",
    "CORTE SUPERIOR DE JUSTICIA DE CUSCO",
    "CORTE SUPERIOR DE JUSTICIA DEL SANTA",
    "CORTE SUPERIOR DE JUSTICIA DE HUANCAVELICA",
    "CORTE SUPERIOR DE JUSTICIA DE HUÁNUCO",
    "CORTE SUPERIOR DE JUSTICIA DE HUAURA",
    "CORTE SUPERIOR DE JUSTICIA DE ICA",
    "CORTE SUPERIOR DE JUSTICIA DE JUNÍN",
    "CORTE SUPERIOR DE JUSTICIA DE LA LIBERTAD",
    "CORTE SUPERIOR DE JUSTICIA DE LAMBAYEQUE",
    "CORTE SUPERIOR DE JUSTICIA DE LIMA",
    "CORTE SUPERIOR DE JUSTICIA DE LIMA NORTE",
    "CORTE SUPERIOR DE JUSTICIA DE LIMA ESTE",
    "CORTE SUPERIOR DE JUSTICIA DE LIMA SUR",
    "CORTE SUPERIOR DE JUSTICIA DE LORETO",
    "CORTE SUPERIOR DE JUSTICIA DE MADRE DE DIOS",
    "CORTE SUPERIOR DE JUSTICIA DE MOQUEGUA",
    "CORTE SUPERIOR DE JUSTICIA DE PASCO",
    "CORTE SUPERIOR DE JUSTICIA DE PIURA",
    "CORTE SUPERIOR DE JUSTICIA DE PUNO",
    "CORTE SUPERIOR DE JUSTICIA DE SAN MARTÍN",
    "CORTE SUPERIOR DE JUSTICIA DE SELVA CENTRAL",
    "CORTE SUPERIOR DE JUSTICIA DE SULLANA",
    "CORTE SUPERIOR DE JUSTICIA DE TACNA",
    "CORTE SUPERIOR DE JUSTICIA DE TUMBES",
    "CORTE SUPERIOR DE JUSTICIA DE UCAYALI",
    "CORTE SUPERIOR DE JUSTICIA DE VENTANILLA",
    "CORTE SUPREMA DE JUSTICIA DE LA REPÚBLICA",
    "OFICINA DE CONTROL DE LA MAGISTRATURA"
  ]