import { DataGrid, GridActionsCellItem, GridRowId, GridToolbarContainer, GridToolbar, esES } from "@mui/x-data-grid";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { iLREvento } from "../../iType";
import { URL_API, folderDefault } from "../../config";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { list_genero, list_mContrato, list_tCargo } from "../../Tools/PlaneData";
import { Button, ButtonProps } from "@mui/material";
import { formatDefaultApp } from "../../Tools/DateTime";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';
import dayjs from "dayjs";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import CorreoPersonalizado from "../../Emergentes/CorreoPersonalizado";

export default function ListRegistro() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const apiRef = useRef<any>({});

  const [lREvento, setLREvento] = useState<iLREvento[]>();

  const CustomToolbar = () => {
    const handleExport = (data: {
      id: number;
      number: number;
      doc: string;
      nombre: string;
      apPaterno: string;
      apMaterno: string;
      celular: string;
      correo: string;
      cargo: string;
      tcargo: string;
      modContrato: string;
      institucion: string;
      genero: string;
      pais: string;
      autorizado: string;
      reg: string;
    }[]) => {

      const columns = apiRef.current.getVisibleColumns();

      let columnsList: string[] = columns.map((item: { field: string }) => item.field);
      let headersList: string[] = columns.map((item: { headerName: string }) => item.headerName);

      // Filtrar el array de objetos JSON y capturar solo las keys deseadas
      const filteredArray = data.map((item: any) => {
        const filteredItem: any = {};
        Object.keys(item).forEach((key) => {
          if (columnsList.includes(key)) {
            filteredItem[headersList[columnsList.indexOf(key)]] = item[key];
          }
        });
        return filteredItem;
      });

      const worksheet = XLSX.utils.json_to_sheet(filteredArray);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Asistencia');
      XLSX.writeFile(workbook, 'Reporte.xlsx');
    }

    const handleAllRowsExport = () => {
      fetch(`${URL_API}/guest/multiple`, {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ ID_EVENTO: state.ID_EVENTO, AUTORIZADO: '1' })
      })
        .finally(() => {
          ListRegistro();
        })
    }

    const handleNoneRowsExport = () => {
      fetch(`${URL_API}/guest/multiple`, {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ ID_EVENTO: state.ID_EVENTO, AUTORIZADO: '0' })
      })
        .finally(() => {
          ListRegistro();
        })
    }

    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <FileDownloadIcon />,
    };

    const buttonAllRowsBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <CheckCircleIcon />,
    };

    const buttonNoneRowsBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <RadioButtonUncheckedIcon />,
    };

    return (
      <GridToolbarContainer>
        <GridToolbar />
        <Button
          {...buttonBaseProps}
          onClick={() => handleExport(gRows())}
        >
          EXCEL
        </Button>
        <Button
          {...buttonAllRowsBaseProps}
          onClick={() => handleAllRowsExport()}
        >
          SELECCIONAR TODO
        </Button>
        <Button
          {...buttonNoneRowsBaseProps}
          onClick={() => handleNoneRowsExport()}
        >
          DESELECCIONAR TODO
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleAuthorizeClick = (id: GridRowId, row: {
    id: number;
    number: number;
    doc: string;
    nombre: string;
    apPaterno: string;
    apMaterno: string;
    celular: string;
    correo: string;
    cargo: string;
    tcargo: string;
    modContrato: string;
    institucion: string;
    genero: string;
    pais: string;
    autorizado: string;
    reg: string;
  }) => () => {
    fetch(`${URL_API}/guest/authorize`, {
      method: "POST",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({ ID_LISTAEVENTO: id, AUTORIZADO: row.autorizado })
    })
      .finally(() => {
        ListRegistro();
      })
  };

  const handleMailClick = (id: GridRowId, row: {
    id: number;
    number: number;
    doc: string;
    nombre: string;
    apPaterno: string;
    apMaterno: string;
    celular: string;
    correo: string;
    cargo: string;
    tcargo: string;
    modContrato: string;
    institucion: string;
    genero: string;
    pais: string;
    autorizado: string;
    reg: string;
  }) => async () => {    
    if (row.autorizado === "1") {
      Swal.fire({
        icon: 'warning',
        title: '¿Seguro que desea enviar el correo?',
        text: `Si acepta se enviará un correo a: "${row.apPaterno} ${row.apMaterno}, ${row.nombre}"`,
        showConfirmButton: true,
        confirmButtonText: 'Sí',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        showDenyButton: true,
        denyButtonText: 'No',
        backdrop: true,
        allowOutsideClick: false,
        customClass: {
          container: 'my-swal-container'
        },
      }).then((response) => {
        if (response.isConfirmed) {
          const MySwal = withReactContent(Swal);

          MySwal.fire({
            title: "Enviar correo",
            showConfirmButton: false,
            html: <>
              <CorreoPersonalizado data={{
                name: row.nombre,
                from: row.correo,// 'grimaldo.legr@gmail.com',
                subject: `Repuesta de: ${state.NOMBRE_EVENTO}`,
                bodyMessage: `<p>Saludos, <strong>${row.nombre}.</strong></p><br><p>Recibimos su solicitud el ${row.reg} a nuestro evento <strong>"${state.NOMBRE_EVENTO}"</strong>, remitimos este mensaje para informarle que ha sido aceptado para participar en nuestro evento que se llevará acabo el día <strong>${state.FINICIO}.</strong></p>`
              }}
                onClose={(result: { response: string }) => {
                  MySwal.fire(<p>{result.response}</p>)
                }}
              />
            </>,
            backdrop: true,
            allowOutsideClick: false,
            customClass: {
              container: 'my-swal-container'
            },
          })
        }
      })
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'No está autorizado',
        text: `Para enviar el correo "${row.nombre}" debe estar autorizado para la reunión`,
        backdrop: true,
        allowOutsideClick: false,
        customClass: {
          container: 'my-swal-container'
        },
      })
    }
  };

  const gRows = () => {
    let result: {
      id: number,
      number: number,
      doc: string,
      nombre: string,
      apPaterno: string,
      apMaterno: string,
      celular: string,
      correo: string,
      cargo: string,
      tcargo: string,
      modContrato: string,
      institucion: string,
      genero: string,
      pais: string,
      autorizado: string,
      reg: string,
    }[] = [];
    lREvento && lREvento.forEach((item, index) => {
      result.push({
        id: parseInt(item.ID_LISTAEVENTO),
        number: index + 1,
        doc: item.DOCUMENTO,
        nombre: item.NOMBRES,
        apPaterno: item.APPATERNO,
        apMaterno: item.APMATERNO,
        celular: item.CELULAR,
        correo: item.CORREO,
        cargo: item.CARGO,
        tcargo: list_tCargo.find(item2 => item2.code === item.T_CARGO)?.label || "-",
        modContrato: list_mContrato.find(item2 => item2.code === item.MOD_CONTRATO)?.label || "-",
        institucion: item.INSTITUCION || "CORTE SUPERIOR DE JUSTICIA",
        genero: list_genero.find(item2 => item2.code === item.GENERO)?.label || "OTRO",
        pais: item.NOM_PAIS || "PERÚ",
        autorizado: item.AUTORIZADO,
        reg: dayjs(item.REGISTRO).format(formatDefaultApp.large)
      })
    }
    )

    return result;
  }

  const ListRegistro = () => {
    if (state) {
      fetch(`${URL_API}/guest/list`, {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ ID_EVENTO: state.ID_EVENTO })
      })
        .then((data) => data.ok && data.json())
        .then((data: iLREvento[]) => {
          setLREvento(data);
        })
    }
  };

  useEffect(() => {
    if (state) {
      ListRegistro();
    } else {
      navigate(`${folderDefault}/event/list`)
    }
  }, [])

  return (
    <div id="list-register">
      <DataGrid className="tableRegister"
        editMode="row"
        columns={[
          { field: "number", headerName: "N°", width: 50 },
          {
            field: 'autorizado',
            type: 'actions',
            headerName: 'Autorizado',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {
              return [
                <GridActionsCellItem
                  icon={row.autorizado !== "0" ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                  label="Authorize"
                  className='AuthorizeAction'
                  onClick={handleAuthorizeClick(id, row)}
                  color="inherit"
                />,
              ];
            },
          },
          { field: "doc", headerName: 'DOCUMENTO', minWidth: 200 },
          { field: "nombre", headerName: 'NOMBRES', minWidth: 200 },
          { field: "apPaterno", headerName: 'A. PATERNO', minWidth: 200 },
          { field: "apMaterno", headerName: 'A. MATERNO', minWidth: 200 },
          { field: "celular", headerName: 'CELULAR', minWidth: 200 },
          { field: "correo", headerName: 'CORREO', minWidth: 300 },
          { field: "cargo", headerName: 'CARGO', minWidth: 200 },
          { field: "tcargo", headerName: 'T_CARGO', minWidth: 200 },
          { field: "modContrato", headerName: 'MOD. CONTRATO', minWidth: 200 },
          { field: "institucion", headerName: 'INTITUCIÓN', minWidth: 700 },
          { field: "genero", headerName: 'GENERO', minWidth: 200 },
          { field: "pais", headerName: 'PAIS', minWidth: 200 },
          // { field: "autorizado", headerName: 'AUTORIZADO', minWidth: 200 },
          { field: "reg", headerName: 'REGISTRO', minWidth: 200 },
          // {
          //   field: 'actCorreo',
          //   type: 'actions',
          //   headerName: 'Correo',
          //   width: 100,
          //   cellClassName: 'actions',
          //   getActions: ({ id, row }) => {
          //     return [
          //       <Tooltip title="Enviar correo">
          //         <GridActionsCellItem
          //           icon={<ForwardToInboxIcon />}
          //           label="Correo"
          //           className='MailAcion'
          //           onClick={handleMailClick(id, row)}
          //           color="inherit"
          //         />
          //       </Tooltip>
          //       ,
          //     ];
          //   },
          // },
        ]}
        rows={gRows()}
        slots={{
          toolbar: CustomToolbar,
        }}
        apiRef={apiRef}
        autoPageSize // Ajusta automáticamente el tamaño del DataGrid
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      />
    </ div>
  );
}