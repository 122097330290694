import React, { useEffect, useState, useMemo, useRef, FC, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { URL_API, folderDefault } from '../../config';
import { FormControl, TextField, Button, Tooltip } from '@mui/material';
import { iGCode, iResponse } from '../../iType';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from "dayjs";
import FormLabel from '@mui/material/FormLabel';
import EditIconMUI from '@mui/icons-material/Edit';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

// Tabla AgGridReact
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { ColGroupDef } from 'ag-grid-community/dist/lib/entities/colDef';

function generateRandomCode() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const codeLength = 5;
  let code = '';
  for (let i = 0; i < codeLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }
  return code;
}

interface tGCode {
  idGCode: string,
  idEvento: string,
  nomEvento: string,
  fInicio: dayjs.Dayjs | null,
  fFinal: dayjs.Dayjs | null,
  minBeforeStart: string,
  minAfterStart: string,
  minBeforeEnd: string,
  minAfterEnd: string,
  clave: string
}

function GenCode() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const gridRef = useRef<AgGridReact>(null);

  const [formValues, setFormValues] = React.useState<tGCode>({
    idGCode: '0',
    idEvento: '',
    nomEvento: '',
    fInicio: null,
    fFinal: null,
    minBeforeStart: '5',
    minAfterStart: '10',
    minBeforeEnd: '5',
    minAfterEnd: '5',
    clave: generateRandomCode()
  });

  const [registrando, setRegistrando] = React.useState(false);

  const [rowData, setRowData] = useState<iGCode[]>([]); // Set rowData to Array of Objects, one Object per Row


  const EditIcon: FC<{ data: { data: iGCode } }> = ({ data }) => (
    <Tooltip
      title="Editar"
      placement="bottom"
      style={{
        cursor: 'pointer'
      }}>
      <EditIconMUI
        onClick={() => {
          setFormValues({
            ...formValues,
            idGCode: data.data.ID_GCODE,
            fInicio: dayjs(data.data.FINICIO),
            fFinal: dayjs(data.data.FFINAL),
            minBeforeStart: data.data.MIN_BEFORE_START,
            minAfterStart: data.data.MIN_AFTER_START,
            minBeforeEnd: data.data.MIN_BEFORE_END,
            minAfterEnd: data.data.MIN_AFTER_END,
            clave: data.data.CLAVE,
          });
        }} />
    </Tooltip>
  );

  // Each Column Definition results in one Column.
  const columnDefs: (ColDef<any> | ColGroupDef<any>)[] | null = [
    { field: "CLAVE", headerName: "CLAVE" },
    {
      field: "FINICIO", headerName: "F. INICIO",
      cellRenderer: (cell: any, data: any, column: any) => {
        return dayjs(cell.value).format("DD/MM/YYYY hh:mm A");
      }
    },
    {
      field: "FFINAL", headerName: "F. FINAL",
      cellRenderer: (cell: any, data: any, column: any) => {
        return dayjs(cell.value).format("DD/MM/YYYY hh:mm A");
      }
    },
    {
      headerName: 'F. INICIO',
      children: [
        {
          field: "MIN_BEFORE_START", headerName: "ANTES",
          valueFormatter: (params) => {
            return params.value + ' min.';
          }
        },
        {
          field: "MIN_AFTER_START", headerName: "DESPUÉS",
          valueFormatter: (params) => {
            return params.value + ' min.';
          }
        },
      ]
    },
    {
      headerName: 'F. FINAL',
      children: [
        {
          field: "MIN_BEFORE_END", headerName: "ANTES",
          valueFormatter: (params) => {
            return params.value + ' min.';
          }
        },
        {
          field: "MIN_AFTER_END", headerName: "DESPUÉS",
          valueFormatter: (params) => {
            return params.value + ' min.';
          }
        },
      ]
    },
    {
      headerName: '',
      field: 'actionField',
      cellRenderer: (params: any) => (
        <EditIcon data={params} /> // Cambia 'star' por el nombre del icono que quieras
      ),
    },
  ];

  const defaultColDef: ColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      cellStyle: { textAlign: 'center', verticalAlign: 'middle' }
    };
  }, []);

  const autoSizeAll = useCallback((skipHeader: any) => {
    const allColumnIds: any[] = [];
    gridRef?.current?.columnApi?.getColumns()?.forEach((column: any) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current?.columnApi?.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  const handleInputChange = (event: React.ChangeEvent<any>) => {
    const condition = ["minBeforeStart", "minAfterStart", "minBeforeEnd", "minAfterEnd"].includes(event.target.name);

    const filteredValue = condition && event.target.value.replace(/[^0-9]/g, '');
    setFormValues({
      ...formValues,
      [event.target.name]: condition ? filteredValue : event.target.value,
    });
  };

  const handlePickerDateTimeChange = (name: string, date: dayjs.Dayjs | null) => {
    setFormValues({
      ...formValues,
      [name]: date,
    });
  };

  const setConfigEvent = () => {
    if (formValues.fInicio !== null || formValues.fFinal !== null) {
      setRegistrando(true);

      fetch(`${URL_API}/gcode/generate`, {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          ID_GCODE: formValues.idGCode,
          ID_EVENTO: state.ID_EVENTO,
          FINICIO: dayjs(formValues.fInicio).format("YYYY-MM-DD HH:mm"),
          FFINAL: dayjs(formValues.fFinal).format("YYYY-MM-DD HH:mm"),
          MIN_BEFORE_START: formValues.minBeforeStart,
          MIN_AFTER_START: formValues.minAfterStart,
          MIN_BEFORE_END: formValues.minBeforeEnd,
          MIN_AFTER_END: formValues.minAfterEnd,
          CLAVE: formValues.clave,
        }),
      })
        .then((response) => response.json())
        .then((data: iResponse) => {
          Swal.fire({
            icon: data.icon as SweetAlertIcon,
            title: data.title,
            text: data.text,
            backdrop: true,
            allowOutsideClick: false,
            customClass: {
              container: 'my-swal-container'
            },
          }).then(() => {
            if (["201", "202"].includes(data.statusCode)) {
              setFormValues({
                ...formValues,
                idGCode: '0',
                idEvento: '',
                nomEvento: '',
                minBeforeStart: '5',
                minAfterStart: '10',
                minBeforeEnd: '5',
                minAfterEnd: '5',
                clave: generateRandomCode()
              });
              loadData();
            }
          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setRegistrando(false);
        })
    } else {
      Swal.fire({
        icon: "warning",
        title: "Campos vacíos",
        text: "Debe rellenar todos los campos",
        backdrop: true,
        allowOutsideClick: false,
        customClass: {
          container: 'my-swal-container'
        },
      })
    }
  };

  const loadData = () => {
    fetch(`${URL_API}/gcode/get`, {
      method: "POST",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({ ID_EVENTO: state.ID_EVENTO })
    })
      .then((data) => data.ok && data.json())
      .then((data: iGCode[]) => {
        let joinData = data.map((item: iGCode) => Object.assign(item, {
          actionField: item.ID_GCODE
        }));

        setRowData(joinData);
      });
  }



  useEffect(() => {
    if (state && state.ID_EVENTO) {
      loadData();
    } else {
      navigate(`${folderDefault}/event/list`)
    }
  }, [state])

  return (
    <div id='g-code'>
      <h2>{`${state.NOMBRE_EVENTO}`}</h2>
      <h4>{`${dayjs(state.FINICIO).format("dddd[,] DD [de] MMMM [de] YYYY [a las] hh:mm A") || ""} | ${dayjs(state.FFINAL).format("dddd[,] DD [de] MMMM [de] YYYY [a las] hh:mm A") || ""}`}</h4>
      <div className="separator">
        <div className='settings-horario'>
          <form method="post" onSubmit={(e) => {
            e.preventDefault();
            setConfigEvent();
          }}>
            <FormControl className="data-config" fullWidth>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Fecha de inicio"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    format="DD/MM/YYYY hh:mm A"
                    onChange={(val) => {
                      handlePickerDateTimeChange("fInicio", val);
                    }}
                    value={formValues.fInicio}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Fecha final"
                    format="DD/MM/YYYY hh:mm A"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    onChange={(val) => {
                      handlePickerDateTimeChange("fFinal", val);
                    }}
                    value={formValues.fFinal}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth>
                <div className='title-group'>
                  <FormLabel id="lblTolStart">Tolerancia al inicio</FormLabel>
                </div>
                <div className='two-component'>
                  <TextField
                    required
                    aria-labelledby='lblTolStart'
                    name="minBeforeStart"
                    label="Antes"
                    placeholder='Ejemplo: "5"'
                    value={formValues.minBeforeStart}
                    inputMode="numeric"
                    autoComplete="off"
                    inputProps={{ maxLength: 2 }}
                    onChange={handleInputChange}
                  />
                  <TextField
                    required
                    name="minAfterStart"
                    label="Después"
                    placeholder='Ejemplo: "5"'
                    value={formValues.minAfterStart}
                    inputMode="numeric"
                    autoComplete="off"
                    inputProps={{ maxLength: 2 }}
                    onChange={handleInputChange}
                  />
                </div>
              </FormControl>
              <FormControl fullWidth>
                <div className='title-group'>
                  <FormLabel id="lblTolStart">Tolerancia al finalizar</FormLabel>
                </div>
                <div className='two-component'>
                  <TextField
                    required
                    name="minBeforeEnd"
                    label="Antes"
                    placeholder='Ejemplo: "5"'
                    value={formValues.minBeforeEnd}
                    inputMode="numeric"
                    autoComplete="off"
                    inputProps={{ maxLength: 2 }}
                    onChange={handleInputChange}
                  />
                  <TextField
                    required
                    name="minAfterEnd"
                    label="Después"
                    placeholder='Ejemplo: "5"'
                    value={formValues.minAfterEnd}
                    inputMode="numeric"
                    autoComplete="off"
                    inputProps={{ maxLength: 2 }}
                    onChange={handleInputChange}
                  />
                </div>
              </FormControl>
              <TextField
                name="clave"
                label="Clave para acceso de asistencia"
                placeholder='Ejemplo: "X4512"'
                value={formValues.clave}
                inputMode="numeric"
                autoComplete="off"
                inputProps={{ maxLength: 10 }}
                onChange={handleInputChange}
              />
              <Button
                type='submit'
                variant="contained"
                color="success"
                style={{ height: "100%" }}
                disabled={registrando}
              >
                {formValues.idGCode !== "0" ? "ACTUALIZAR" : "REGISTRAR"}
              </Button>
            </FormControl>
          </form>
        </div>
        <div className='list-horario'>
          <div className="ag-theme-alpine" >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              animateRows={true}
              // rowSelection='multiple'
              suppressRowClickSelection={true}
              suppressAggFuncInHeader={true}
              pagination={true}
              paginationAutoPageSize={true}
              paginateChildRows={true}
              floatingFiltersHeight={50}
              defaultColDef={defaultColDef}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenCode