import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  esES
} from "@mui/x-data-grid";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { iLREvento } from "../../iType";
import { URL_API, folderDefault } from "../../config";
import { list_genero, list_mContrato, list_tCargo } from "../../Tools/PlaneData";
import { Button, ButtonProps } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';
import dayjs from "dayjs";
import { formatDefaultApp } from "../../Tools/DateTime";

export default function ListRegistroAsistencia() {
  const { state } = useLocation();
  const navigate = useNavigate();
  // Referencia a la API de DataGrid
  const apiRef = useRef<any>({});
  const [lREvento, setLREvento] = useState<iLREvento[]>();

  const CustomToolbar = () => {
    const handleExport = (data: {
      id: number,
      number: number,
      doc: string,
      nombre: string,
      apPaterno: string,
      apMaterno: string,
      celular: string,
      correo: string,
      cargo: string,
      tcargo: string,
      modContrato: string,
      institucion: string,
      genero: string,
      pais: string,
      autorizado: string,
      ingreso: string,
      salida: string,
      reg: string,
    }[]) => {

      const columns = apiRef.current.getVisibleColumns();

      let columnsList: string[] = columns.map((item: { field: string }) => item.field);
      let headersList: string[] = columns.map((item: { headerName: string }) => item.headerName);

      // Filtrar el array de objetos JSON y capturar solo las keys deseadas
      const filteredArray = data.map((item: any) => {
        const filteredItem: any = {};
        Object.keys(item).forEach((key) => {
          if (columnsList.includes(key)) {
            filteredItem[headersList[columnsList.indexOf(key)]] = item[key];
          }
        });
        return filteredItem;
      });

      const worksheet = XLSX.utils.json_to_sheet(filteredArray);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Asistencia');
      XLSX.writeFile(workbook, 'Reporte.xlsx');
    }

    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <FileDownloadIcon />,
    };

    return (
      <GridToolbarContainer>
        <GridToolbar />
        <Button
          {...buttonBaseProps}
          onClick={() => handleExport(gRows())}
        >
          EXCEL
        </Button>
      </GridToolbarContainer>
    );
  }

  const gRows = () => {
    let result: {
      id: number,
      number: number,
      doc: string,
      nombre: string,
      apPaterno: string,
      apMaterno: string,
      celular: string,
      correo: string,
      cargo: string,
      tcargo: string,
      modContrato: string,
      institucion: string,
      genero: string,
      pais: string,
      autorizado: string,
      ingreso: string,
      salida: string,
      reg: string,
    }[] = [];
    lREvento && lREvento.forEach((item, index) => {
      result.push({
        id: parseInt(item.ID_LISTAEVENTO),
        number: index + 1,
        doc: item.DOCUMENTO,
        nombre: item.NOMBRES,
        apPaterno: item.APPATERNO,
        apMaterno: item.APMATERNO,
        celular: item.CELULAR,
        correo: item.CORREO,
        cargo: item.CARGO,
        tcargo: list_tCargo.find(item2 => item2.code === item.T_CARGO)?.label || "-",
        modContrato: list_mContrato.find(item2 => item2.code === item.MOD_CONTRATO)?.label || "-",
        institucion: item.INSTITUCION || "CORTE SUPERIOR DE JUSTICIA",
        genero: list_genero.find(item2 => item2.code === item.GENERO)?.label || "OTRO",
        pais: item.NOM_PAIS || "PERÚ",
        autorizado: item.AUTORIZADO || "0",
        ingreso: item.REGISTRO_INGRESO ? dayjs(item.REGISTRO_INGRESO).format(formatDefaultApp.large) : "-",
        salida: item.REGISTRO_SALIDA ? dayjs(item.REGISTRO_SALIDA).format(formatDefaultApp.large) : "-",
        reg: item.REGISTRO ? dayjs(item.REGISTRO).format(formatDefaultApp.large) : "-"
      })
    }
    )

    return result;
  }

  const ListRegistroAsistencia = () => {
    if (state) {
      fetch(`${URL_API}/guest/assistants`, {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ ID_EVENTO: state.ID_EVENTO, ID_GCODE: state.ID_GCODE })
      })
        .then((data) => data.ok && data.json())
        .then((data: iLREvento[]) => {
          setLREvento(data);
        })
    }
  };

  useEffect(() => {
    if (state) {
      ListRegistroAsistencia();
    } else {
      navigate(`${folderDefault}/event/myevents`)
    }
  }, [])

  return (
    <div id="list-register">
      <DataGrid className="tableRegister"
        editMode="row"
        columns={[
          { field: "number", headerName: "N°", width: 50 },
          { field: "doc", headerName: 'DOCUMENTO', minWidth: 200 },
          { field: "nombre", headerName: 'NOMBRES', minWidth: 200 },
          { field: "apPaterno", headerName: 'A. PATERNO', minWidth: 200 },
          { field: "apMaterno", headerName: 'A. MATERNO', minWidth: 200 },
          { field: "celular", headerName: 'CELULAR', minWidth: 200 },
          { field: "correo", headerName: 'CORREO', minWidth: 300 },
          { field: "cargo", headerName: 'CARGO', minWidth: 200 },
          { field: "tcargo", headerName: 'T_CARGO', minWidth: 200 },
          { field: "modContrato", headerName: 'MOD. CONTRATO', minWidth: 200 },
          { field: "institucion", headerName: 'INTITUCIÓN', minWidth: 700 },
          { field: "genero", headerName: 'GENERO', minWidth: 200 },
          { field: "pais", headerName: 'PAIS', minWidth: 200 },
          { field: "ingreso", headerName: 'R. INGRESO', minWidth: 200 },
          { field: "salida", headerName: 'R. SALIDA', minWidth: 200 },
          { field: "reg", headerName: 'REGISTRO', minWidth: 200 },
        ]}
        rows={gRows()}
        slots={{
          toolbar: CustomToolbar
        }}
        apiRef={apiRef}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      />
    </ div>
  );
}