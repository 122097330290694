import { useState, useEffect } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { DataGrid, GridActionsCellItem, GridRowClassNameParams, GridRowId, GridToolbarContainer, GridToolbarExport, esES } from '@mui/x-data-grid';
import { iLTEvento, iResponse } from '../../iType';
import { URL_API } from '../../config';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

function TEventoCreador() {
  // Capturar valores del formulario
  const [formValues, setFormValues] = useState({
    idTEvento: '0',
    nomTEvento: ''
  });

  const [LTEvento, setLTEvento] = useState<iLTEvento[]>([]);


  // ||||| EVENTOS  ||||||||
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value.trimStart(),
    }));
  };

  const getTEvento = () => {
    // Crear o modificar equipo
    fetch(`${URL_API}/tevent/listAll`)
      .then(resp => resp.json())
      .then((result: iLTEvento[]) => {
        if (result.length > 0) {
          setLTEvento(result);
        }
      })
  }

  // ||||| ENVIOS DE DATOS |||||
  // event: React.FormEvent<HTMLFormElement>
  const SaveChanged = () => {
    // Crear o modificar
    fetch(`${URL_API}/tevent/create`, {
      method: "POST",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        "ID_TEVENTO": formValues.idTEvento,
        "NOM_TEVENTO": formValues.nomTEvento
      })
    })
      .then(resp => resp.json())
      .then((result: iResponse) => {
        Swal.fire({
          icon: result.icon as SweetAlertIcon,
          title: result.title,
          text: result.text,
          backdrop: true,
          allowOutsideClick: false,
          customClass: {
            container: 'my-swal-container'
          },
        }).then((resp) => {
          if (resp.isConfirmed) {
            // Limpiar inputs
            if (result.statusCode === "201" || result.statusCode === "202") {
              setFormValues({
                ...formValues,
                idTEvento: '0',
                nomTEvento: '',
              });
            }
          }
        })
      })
      .catch((err) =>
        Swal.fire({
          icon: 'error',
          title: 'ERROR',
          text: err,
          backdrop: true,
          allowOutsideClick: false,
          customClass: {
            container: 'my-swal-container'
          },
        })
      )
      .finally(() => {
        getTEvento();
      })
  }

  const handleEditClick = (id: GridRowId) => () => {
    let itemSelected = LTEvento.find(item => item.ID_TEVENTO === id.toString());

    setFormValues({
      ...formValues,
      "idTEvento": itemSelected?.ID_TEVENTO || "0",
      "nomTEvento": itemSelected?.NOM_TEVENTO || ""
    })
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    Swal.fire({
      icon: 'question',
      title: 'MENSAJE DEL SISTEMA',
      text: '¿Seguro que desea eliminar el siguiente elemento?',
      confirmButtonText: 'Sí',
      showCancelButton: true,
      showDenyButton: true,
      backdrop: true,
      allowOutsideClick: false,
      customClass: {
        container: 'my-swal-container'
      },
    })
      .then((resp) => {
        if (resp.isConfirmed) {
          fetch(`${URL_API}/tevent/del`, {
            method: "POST",
            headers: {
              "content-type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({
              "ID_TEVENTO": id
            }),
          })
            .then(resp => resp.json())
            .then((result: iResponse) => {
              Swal.fire({
                icon: result.icon as SweetAlertIcon,
                title: result.title,
                text: result.text,
                backdrop: true,
                allowOutsideClick: false,
                customClass: {
                  container: 'my-swal-container'
                },
              });
            }).finally(() => {
              getTEvento();
            })
        }
      })
  };

  const gRows = () => {
    let result: {
      id: number,
      number: number,
      nombre: string,
      estado: string,
      state: string
    }[] = [];
    LTEvento && LTEvento.forEach((item, index) => {
      result.push({
        id: parseInt(item.ID_TEVENTO),
        number: index + 1,
        nombre: item.NOM_TEVENTO || "-",
        estado: item.ESTADO === "0" ? "ELIMINADO" : "ACTIVO",
        state: item.ESTADO
      });
    }
    )
    return result;
  }

  // ||||| MINICOMPONENTES ||||||
  // Exportar tabla
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const getRowClassName = (params: GridRowClassNameParams<{ state: string }>) => {
    // Condición para aplicar estilos a una fila específica
    if (params.row.state === "0") {
      return 'remove-row'; // Clase CSS personalizada para la fila
    }
    return ''; // Sin estilos adicionales para las demás filas
  };

  useEffect(() => {
    getTEvento();
  }, []);


  return (
    <Grid container spacing={2} justifyContent={"center"} className='crudTables'>
      <Grid xs={4} className='container-duplex'>
        <form onSubmit={(e) => { e.preventDefault(); SaveChanged(); }}>
          <FormControl fullWidth className='elementsRows'>
            <TextField
              required
              name="nomTEvento"
              label="Nombre de tipo de evento"
              placeholder='Ejemplo: "CONFERENCIA"'
              autoComplete='off'
              inputProps={{ maxLength: 50 }}
              value={formValues.nomTEvento}
              onChange={handleChange}
            />
            <Button
              type="submit"
              variant="contained"
              color="success"
              style={{ maxWidth: 250, fontWeight: "bold", margin: "auto" }}
            >
              {
                formValues.idTEvento !== "0" ?
                  "Actualizar" : "Registrar"
              }
            </Button>
            <br />
            {
              formValues.idTEvento !== "0" &&
              <Button
                type="button"
                variant="contained"
                color="warning"
                onClick={() => {
                  setFormValues({
                    ...formValues,
                    idTEvento: '0',
                    nomTEvento: '',
                  });
                }}
                style={{ maxWidth: 250, fontWeight: "bold", margin: "auto" }}
              >
                Cancelar
              </Button>
            }
          </FormControl>
        </form>
      </Grid>
      <Grid xs={8}>
        <DataGrid
          editMode="row"
          columns={[
            { field: "number", headerName: "N°" },
            { field: "nombre", headerName: 'NOMBRE', minWidth: 400 },
            { field: "estado", headerName: 'ESTADO', minWidth: 150 },
            {
              field: 'actions',
              type: 'actions',
              headerName: 'Acciones',
              width: 100,
              cellClassName: 'actions',
              getActions: ({ id, row }) => {
                return [
                  <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="editAction"
                    onClick={handleEditClick(id)}
                    color="inherit"
                    disabled={row.state === "0"}
                  />,
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    className='deleteAction'
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                    disabled={row.state === "0"}
                  />,
                ];
              },
            },
          ]}
          getRowClassName={getRowClassName}
          rows={gRows()}
          slots={{
            toolbar: CustomToolbar,
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
      </Grid>
    </Grid>
  )
}

export default TEventoCreador