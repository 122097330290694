import { useEffect, useState } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { URL_API } from "../../config";
import { getInfoUser } from "../../Tools/Auth";
import { iLUsuario } from '../../iType';

function ListUsers() {
  const [lUsuarios, setLUsuarios] = useState<iLUsuario[]>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const listaUsuarios = () => {
    fetch(`${URL_API}/usuario/list`, {
      method: "POST",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({ idAdmin: getInfoUser()?.ID_USUARIO, pageSize: paginationModel.pageSize || 25 })
    })
      .then((data) => data.ok && data.json())
      .then((data: iLUsuario[]) => {
        setLUsuarios(data);
      })
  };

  useEffect(() => {
    listaUsuarios();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   console.log(paginationModel);
  // }, [paginationModel])

  const gRows = () => {
    let result: {
      id: number,
      number: number,
      doc: string,
      nombres: string,
      apPaterno: string,
      apMaterno: string,
      privilegio: string
    }[] = [];
    lUsuarios && lUsuarios.forEach((item, index) => {
      result.push({
        id: parseInt(item.ID_USUARIO),
        number: index + 1,
        doc: item.DOCUMENTO,
        nombres: item.NOMBRES,
        apPaterno: item.APPATERNO,
        apMaterno: item.APMATERNO,
        privilegio: item.NOMBRE_PRIVILEGIO
      })
    }
    )

    return result;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div id="my-events">
      <DataGrid
        editMode="row"
        columns={[
          { field: "number", headerName: "N°", },
          { field: "doc", headerName: 'DOCUMENTO', minWidth: 150 },
          { field: "nombres", headerName: 'NOMBRES', minWidth: 200 },
          { field: "apPaterno", headerName: 'AP. PATERNO', minWidth: 150 },
          { field: "apMaterno", headerName: 'AP. MATERNO', minWidth: 150 },
          { field: "privilegio", headerName: 'PRIVILEGIO', minWidth: 150 }
        ]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rows={gRows()}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
    </div>
  )
}

export default ListUsers;