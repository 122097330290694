// DETERMINAR ANIO BISIESTO
const esBisiesto = (year: number) => {
  return year % 400 === 0 ? true : year % 100 === 0 ? false : year % 4 === 0;
};

// DETERMINAR SI ES PAR
const esPar = (number: number) => {
  return number % 2 === 0;
};

// OBTERNER DIAS DE UN MES EN ESPECIFICO
const obtNumDias = (numberMonth: number, year = 1) => {
  if (numberMonth === 2) return esBisiesto(year) ? 29 : 28;
  else if (numberMonth < 8) return esPar(numberMonth) ? 30 : 31;
  else if (numberMonth < 13) return esPar(numberMonth) ? 31 : 30;
  else return 0;
};

const ObtEdad = (today: Date, birthday: Date) => {
  // Parametros
  let diffDay = today.getDate() - birthday.getDate();
  let diffMonth = today.getMonth() - birthday.getMonth();
  diffMonth += diffDay < 0 ? -1 : 0;
  let diffYear = today.getFullYear() - birthday.getFullYear();

  // Salidas
  let yearCount = diffYear - (diffMonth < 0 ? 1 : 0);
  let monthCount = diffMonth < 0 ? 12 + diffMonth : diffMonth;
  let dayCount =
    (diffDay < 0 ? birthday.getDate() + diffDay : diffDay) +
    (diffDay < 0
      ? obtNumDias(today.getMonth(), today.getFullYear() - 1) -
      birthday.getDate()
      : 0);

  return {
    anios: yearCount,
    meses: monthCount,
    dias: dayCount,
  };
};

const getEdadString = (feNac: string) => {
  const edad = ObtEdad(new Date(Date.now()), new Date(feNac.substring(6, 10) + "/" + feNac.substring(3, 5) + "/" + feNac.substring(0, 2)));
  return `${edad.anios} año${edad.anios !== 1 ? 's' : ''}, ${edad.meses} mes${edad.meses !== 1 ? 'es' : ''} y ${edad.dias} día${edad.dias !== 1 ? 's' : ''}`;
}


// Convierte de DD/MM/YYYY a YYYY-MM-DD
const transformDate = (feNac: string) => {
  return feNac.substring(6, 10) + "-" + feNac.substring(3, 5) + "-" + feNac.substring(0, 2);
}

const formatDefaultApp = {
  large: "DD/MM/YYYY hh:mm A",
  date: "DD/MM/YYYY",
  time12: "hh:mm A",
  time24: "HH:mm"
}

export { ObtEdad, getEdadString, transformDate, formatDefaultApp }