import { useEffect, FC, useState } from "react";
import { Button } from "@mui/material";
import { URL_API } from "../config";
import { iGCode } from "../iType";
import dayjs from "dayjs";

interface iListasAsistencia {
  info: {
    ID_EVENTO: string,
    NOMBRE_EVENTO: string
  }
  onClose: () => void,
  goTo: (ID_GCODE: string) => void
}

const ListasAsistencia: FC<iListasAsistencia> = ({ info, onClose, goTo }) => {
  const [data, setData] = useState<iGCode[]>();

  const loadData = () => {
    fetch(`${URL_API}/gcode/list`, {
      method: "POST",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({ ID_EVENTO: info.ID_EVENTO })
    })
      .then((data) => {
        if (!data.ok) {
          throw new Error(`HTTP error! Status: ${data.status}`);
        }
        return data.json()
      })
      .then((data: iGCode[]) => {
        setData(data);
      });
  }

  useEffect(() => {
    if (info.ID_EVENTO.length > 0) {
      loadData();
    }
  }, [info.ID_EVENTO])

  return (
    <div>
      {
        data?.map((item: iGCode, index: number) => (
          <Button
            key={index + 1}
            type='submit'
            variant="contained"
            color="info"
            style={{ height: "100%", width: "100%", marginBottom: 10 }}
            onClick={() => {
              goTo(item.ID_GCODE);
            }}
          >
            {`${dayjs(item.FINICIO).format("DD/MM/YYYY hh:mm A")} - ${item.MIN_BEFORE_START} min | ${dayjs(item.FFINAL).format("DD/MM/YYYY hh:mm A")} + ${item.MIN_AFTER_END} min`}
          </Button>
        ))
      }
      <Button
        type='submit'
        variant="contained"
        color="warning"
        style={{ height: "100%" }}
        onClick={() => {
          onClose()
        }}
      >
        CANCELAR
      </Button>
    </div >
  )
}

export default ListasAsistencia