// no-template-curly-in-string
const AG_GRID_LOCALE_ES = {
    // Filtro Set
    selectAll: '(Seleccionar todo)',
    selectAllSearchResults: '(Seleccionar todos los resultados de búsqueda)',
    searchOoo: 'Buscar...',
    blanks: '(Vacíos)',
    noMatches: 'No hay coincidencias',

    // Filtro de número y texto
    filterOoo: 'Filtrar...',
    equals: 'Igual',
    notEqual: 'No igual',
    blank: 'Vacío',
    notBlank: 'No vacío',
    empty: 'Seleccionar uno',

    // Filtro de número
    lessThan: 'Menor que',
    greaterThan: 'Mayor que',
    lessThanOrEqual: 'Menor o igual que',
    greaterThanOrEqual: 'Mayor o igual que',
    inRange: 'En rango',
    inRangeStart: 'desde',
    inRangeEnd: 'hasta',

    // Filtro de texto
    contains: 'Contiene',
    notContains: 'No contiene',
    startsWith: 'Comienza con',
    endsWith: 'Termina con',

    // Filtro de fecha
    dateFormatOoo: 'yyyy-mm-dd',

    // Condiciones del filtro
    andCondition: 'Y',
    orCondition: 'O',

    // Botones del filtro
    applyFilter: 'Aplicar',
    resetFilter: 'Restablecer',
    clearFilter: 'Limpiar',
    cancelFilter: 'Cancelar',

    // Títulos del filtro
    textFilter: 'Filtro de texto',
    numberFilter: 'Filtro de número',
    dateFilter: 'Filtro de fecha',
    setFilter: 'Establecer filtro',

    // Filtro de columna de grupo
    groupFilterSelect: 'Seleccionar campo:',

    // Filtro avanzado
    advancedFilterContains: 'contiene',
    advancedFilterNotContains: 'no contiene',
    advancedFilterTextEquals: 'igual',
    advancedFilterTextNotEqual: 'no igual',
    advancedFilterStartsWith: 'comienza con',
    advancedFilterEndsWith: 'termina con',
    advancedFilterBlank: 'está vacío',
    advancedFilterNotBlank: 'no está vacío',
    advancedFilterEquals: '=',
    advancedFilterNotEqual: '!=',
    advancedFilterGreaterThan: '>',
    advancedFilterGreaterThanOrEqual: '>=',
    advancedFilterLessThan: '<',
    advancedFilterLessThanOrEqual: '<=',
    advancedFilterTrue: 'es verdadero',
    advancedFilterFalse: 'es falso',
    advancedFilterAnd: 'Y',
    advancedFilterOr: 'O',
    advancedFilterApply: 'Aplicar',
    advancedFilterValidationMissingColumn: 'Falta la columna',
    advancedFilterValidationMissingOption: 'Falta la opción',
    advancedFilterValidationMissingValue: 'Falta el valor',
    advancedFilterValidationInvalidColumn: 'Columna no encontrada',
    advancedFilterValidationInvalidOption: 'Opción no encontrada',
    advancedFilterValidationMissingQuote: 'Falta una comilla de cierre en el valor',
    advancedFilterValidationNotANumber: 'El valor no es un número',
    advancedFilterValidationMissingCondition: 'Falta la condición',
    advancedFilterValidationJoinOperatorMismatch: 'Los operadores de unión dentro de una condición deben ser los mismos',
    advancedFilterValidationInvalidJoinOperator: 'Operador de unión no encontrado',
    advancedFilterValidationMissingEndBracket: 'Falta el corchete de cierre',
    advancedFilterValidationExtraEndBracket: 'Hay demasiados corchetes de cierre',
    advancedFilterValidationMessage: 'La expresión tiene un error. ${variable1} - ${variable2}.',
    advancedFilterValidationMessageAtEnd: 'La expresión tiene un error. ${variable} al final de la expresión.',

    // Barra lateral
    columns: 'Columnas',
    filters: 'Filtros',

    // Herramienta de columnas en el panel de herramientas
    pivotMode: 'Modo de pivote',
    groups: 'Agrupaciones de filas',
    rowGroupColumnsEmptyMessage: 'Arrastra aquí para establecer agrupaciones de filas',
    values: 'Valores',
    valueColumnsEmptyMessage: 'Arrastra aquí para agregar',
    pivots: 'Etiquetas de columna',
    pivotColumnsEmptyMessage: 'Arrastra aquí para establecer etiquetas de columna',

    // Encabezado de la columna de grupo predeterminado
    group: 'Agrupar',

    // Arrastre de filas
    rowDragRow: 'fila',
    rowDragRows: 'filas',

    // Otros
    loadingOoo: 'Cargando...',
    loadingError: 'ERR',
    noRowsToShow: 'No hay filas para mostrar',
    enabled: 'Habilitado',

    // Menú
    pinColumn: 'Anclar columna',
    pinLeft: 'Anclar a la izquierda',
    pinRight: 'Anclar a la derecha',
    noPin: 'No anclar',
    valueAggregation: 'Agregación de valores',
    noAggregation: 'Ninguno',

    page: 'Página',
    to: 'a',
    of: 'de',
    first: 'Primera',
    last: 'Última',
    next: 'Siguiente',
    previous: 'Anterior',
}

export default AG_GRID_LOCALE_ES;