import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconDefinition,
  faCalendar,
  faCalendarCheck,
  faCalendarPlus,
  faChevronDown,
  faChevronUp,
  faGlobe,
  faHammer,
  faUserPlus,
  faUsers,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { NavLink } from "react-router-dom";
import { folderDefault } from "../config";
import { allowPermission } from "../Tools/Auth";
import { useState } from "react";

const ItemCategory:
  React.FC<{ title: string, setItem: () => any, icon: IconDefinition, open: boolean }> =
  ({ title, setItem, icon, open }) => {
    return (
      <Tooltip title={title} placement="right">
        <ListItemButton onClick={setItem}>
          <ListItemIcon>
            <FontAwesomeIcon icon={icon} size="xl" />
          </ListItemIcon>
          <ListItemText primary={title} />
          {open ?
            <FontAwesomeIcon icon={faChevronUp} size="sm" /> :
            <FontAwesomeIcon icon={faChevronDown} size="sm" />}
        </ListItemButton>
      </Tooltip>
    )
  }

const ItemSubCategory:
  React.FC<{ link: string, title: string, icon: IconDefinition }> =
  ({ link, title, icon }) => {
    return (
      <Tooltip title={title} placement="right">
        <NavLink to={`${folderDefault}${link}`}
          className={({ isActive }) => (isActive ? "active" : "")}
          style={{ textDecoration: 'none', color: "#707070" }}>
          <List component="div" disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <FontAwesomeIcon icon={icon} size="1x" />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItemButton>
          </List>
        </NavLink>
      </Tooltip >)
  }

function Navbar() {
  const [isCatUsuario, setIsCatUsuario] = useState(false);
  const [isCatEventos, setIsCatEventos] = useState(false);
  const [isCatMantenimiento, setIsCatMantenimiento] = useState(false);

  return (
    <>
      {
        allowPermission(["ADMINISTRADOR"]) &&
        <>
          <ItemCategory
            title="Usuarios"
            setItem={() => setIsCatUsuario(!isCatUsuario)}
            icon={faUsers}
            open={isCatUsuario}
          />
          <Collapse in={isCatUsuario} timeout="auto" unmountOnExit>
            <ItemSubCategory
              link={`/user/list`}
              title="Administrar"
              icon={faUsersViewfinder}
            />
            <ItemSubCategory
              link={`/user/create`}
              title="Nuevo"
              icon={faUserPlus}
            />
          </Collapse>
        </>
      }
      {
        allowPermission(["ADMINISTRADOR", "OPERADOR"]) &&
        <>
          <ItemCategory
            title="Eventos"
            setItem={() => setIsCatEventos(!isCatEventos)}
            icon={faCalendar}
            open={isCatEventos}
          />
          <Collapse in={isCatEventos} timeout="auto" unmountOnExit>
            <ItemSubCategory
              link={`/event/create`}
              title="Nuevo"
              icon={faCalendarPlus}
            />
            <ItemSubCategory
              link={`/event/myevents`}
              title="Mis eventos"
              icon={faCalendar}
            />
          </Collapse>
        </>
      }

      {
        allowPermission(["ADMINISTRADOR", "OPERADOR"]) &&
        <>
          <ItemCategory
            title="Mantenimiento"
            setItem={() => setIsCatMantenimiento(!isCatMantenimiento)}
            icon={faHammer}
            open={isCatEventos}
          />
          <Collapse in={isCatMantenimiento} timeout="auto" unmountOnExit>
            <ItemSubCategory
              link={`/maintenance/country`}
              title="Pais"
              icon={faGlobe}
            />
            <ItemSubCategory
              link={`/maintenance/tevento`}
              title="Tipo evento"
              icon={faCalendarCheck}
            />
          </Collapse>
        </>
      }

      <NavLink to={`${folderDefault}/event/list`}
        className={({ isActive }) => (isActive ? "active" : "")}
        style={{ textDecoration: 'none', color: "#707070" }}>
        <ListItemButton>
          <ListItemIcon>
            <FontAwesomeIcon icon={faCalendar} size="xl" />
          </ListItemIcon>
          <ListItemText primary="Eventos públicos" />
        </ListItemButton>
      </NavLink>
    </>
  );
}

export default Navbar;
