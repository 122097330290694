import React from 'react';
import './error.css';
import LogoImage from '../../images/img1.svg';

const ErrorPage: React.FC = () => {
  return (
    <body className='error-body'>
      <div className="container-error">
        <img src={LogoImage} alt="Logo"/>
        <h1 className='h1-error'>La página que ha solicitado no se encuentra disponible</h1>
        <div className="boton">
          <a className='button-error' href="/meeting">Volver atrás</a>
          {/* <button className='button-error'>Volver atrás</button> */}
        </div>
      </div>
    </body>
  );
};

export default ErrorPage;