import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../logo.poder.judicial.png';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { folderDefault, URL_API } from '../config';
import { iUsuario } from '../iType';
import fotoPJ from '../images/csjica.jpg';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright ©'}
      <Link color="inherit" href="https://www.pj.gob.pe/wps/wcm/connect/cortesuperioricapj/s_csj_ica_nuevo/as_inicio" target='__blank'>
        {" CSJICA "}
      </Link>
      {`${new Date().getFullYear()}.`}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignInSide() {
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    fetch(`${URL_API}/usuario/login`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ doc: data.get("document"), pass: data.get("password") })
      })
      .then((resp) => resp.json())
      .then((data: iUsuario[]) => {
        if (data && data.length > 0 && data[0] !== null) {
          Swal.fire({
            icon: "success",
            title: "MENSAJE DEL SISTEMA",
            text: `¡Bienvenido ${data[0].NOMBRES}!`,
            backdrop: true,
            allowOutsideClick: false,
            customClass: {
              container: 'my-swal-container'
            },
          }).then((resp) => {
            if (resp.isConfirmed) {
              localStorage.setItem('session', JSON.stringify(data[0]));
              navigate(`${folderDefault}/event/list`)
            }
          })
        } else {
          Swal.fire({
            icon: "warning",
            title: "¡CREDENCIALES INCORRECTOS!",
            text: "Verifique sus credenciales, por favor.",
            backdrop: true,
            allowOutsideClick: false,
            customClass: {
              container: 'my-swal-container'
            },
          })
        }
      })
      .catch((err)=>{
        Swal.fire({
          icon: "error",
          title: "ERROR",
          text: err,
          backdrop: true,
          allowOutsideClick: false,
          customClass: {
            container: 'my-swal-container'
          },
        })
      })
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${fotoPJ})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#33333310', height: 100, width: 100 }}>
              <img src={Logo} style={{ width: "60%", height: "60%" }} />
            </Avatar>
            <Typography component="h1" variant="h5">
              Iniciar sesión
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="document"
                label="Ingrese documento"
                name="document"
                autoComplete="off"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Recuérdame"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ background: "#3c8dbc" }}
                sx={{ mt: 3, mb: 2 }}
              >
                ENTRAR
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    {/* ¿Olvidaste tu contraseña? */}
                  </Link>
                </Grid>
                <Grid item>
                  <Link href={`${folderDefault}/event/list`} variant="body2">
                    {"Soy invitado"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}