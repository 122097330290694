import { useEffect, useState, ChangeEvent, SyntheticEvent, Fragment } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useParams } from "react-router-dom";
import { URL_API, folderDefault } from '../../config';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { iLEvento, iLPais, iResponse } from '../../iType';
import { corteList, list_genero, list_mContrato, list_tCargo, tabsList } from '../../Tools/PlaneData';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from "dayjs";
import 'dayjs/locale/es' // carga bajo demanda
import { esES } from '@mui/x-date-pickers';

function RegisterPerson() {
  const navigate = useNavigate();
  const { evento } = useParams<{ evento: string }>();

  const [formValues, setFormValues] = useState({
    idEvento: '',
    nomEvento: '',
    documento: '',
    nombres: '',
    apPaterno: '',
    apMaterno: '',
    celular: '',
    correo: '',
    cargo: '',
    tCargo: '',
    modContrato: '',
    institucion: '',
    genero: '',
    pais: '',
    permiso: false,
    respuesta: ''
  });

  const [info, setInfo] = useState({
    DOC: "",
    NOMBRES: "",
    PREGUNTA: "",
    DESBLOQUEO: "",
  })
  const [registrando, setRegistrando] = useState(false);
  const [typeGuest, setTypeGuest] = useState(0);
  const [lPais, setLPais] = useState<iLPais[]>([]);
  const [errorDNI, setErrorDni] = useState("");
  const [errorSecurity, setErrorSecurity] = useState("");

  const [_, setCortes] = useState<string | null>(corteList[0]);

  const handleTabChange = (
    _: SyntheticEvent,
    guestPost: number,
  ) => {
    setTypeGuest(guestPost);
  };

  const getInfo = () => {
    fetch(`${URL_API}/evento/unique`, {
      method: "POST",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        ID_EVENTO: evento,
      }),
    })
      .then((response) => response.json())
      .then((data: iLEvento[]) => {
        if (data.length > 0) {
          setFormValues({
            ...formValues,
            idEvento: data[0].ID_EVENTO,
            nomEvento: data[0].NOMBRE_EVENTO,
          });
        }
        else {
          Swal.fire({
            icon: "warning",
            title: "Evento no disponible",
            text: "El evento no se encuentra disponible",
            backdrop: true,
            allowOutsideClick: false,
            customClass: {
              container: 'my-swal-container'
            },
          }).then(() => {
            navigate(`${folderDefault}/event/list`)
          })
        }
      })
      .catch((err) => console.error(err))
  };

  const handlePickerDateChange = (name: string, date: string | null) => {
    setFormValues({
      ...formValues,
      [name]: dayjs(date),
    });
  };

  const obtenerPregunta = (doc: string) => {
    fetch(`${URL_API}/persona/question/${doc}`, {
      method: "GET",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
    })
      .then((resp) => resp.json())
      .then(
        (
          data: {
            DOC: string;
            NOMBRES: string;
            PREGUNTA: string;
            DESBLOQUEO: string;
          }[]
        ) => {
          setInfo(data[0]);
        }
      );
  };

  const handleInputChange = (event: ChangeEvent<any>) => {
    const condition = event.target.name === "documento" && typeGuest !== 2;
    const filteredValue = condition && event.target.value.replace(typeGuest !== 2 ? /[^0-9]/g : /[^a-zA-Z0-9]/g, '');
    setFormValues({
      ...formValues,
      [event.target.name]: condition ? filteredValue : event.target.value,
    });
    if (condition) {
      if (event.target.value.length === 8) {
        // obtenerPregunta(filteredValue);
      }
      else {
        setInfo({
          DOC: "",
          NOMBRES: "",
          PREGUNTA: "",
          DESBLOQUEO: "",
        })
      }
    }
  }

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckedChange = (event: ChangeEvent<any>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.checked,
    });
  }

  const FormDataValid = () => {
    switch (typeGuest) {
      case 0:
        setFormValues((prevValues) => ({
          ...prevValues,
          documento: "",
          tCargo: "",
          modContrato: "",
          pais: "",
          respuesta: '',
          institucion: ''
        }));
        break;
      case 1:
        setFormValues((prevValues) => ({
          ...prevValues,
          documento: "",
          pais: "",
          respuesta: '',
          institucion: ''
        }));
        break;
      case 2:
        setFormValues((prevValues) => ({
          ...prevValues,
          documento: "",
          tCargo: "",
          modContrato: "",
          respuesta: '',
          institucion: ''
        }));
        getPais();
        break;
    }
    setInfo({
      DOC: "",
      NOMBRES: "",
      PREGUNTA: "",
      DESBLOQUEO: "",
    })
  }

  const registrarEvento = () => {
    if (formValues.permiso) {

      const formData = {
        TINVITADO: typeGuest,
        DOCUMENTO: formValues.documento,
        NOMBRES: formValues.nombres,
        APPATERNO: formValues.apPaterno,
        APMATERNO: formValues.apMaterno,
        CELULAR: formValues.celular,
        CORREO: formValues.correo,
        CARGO: formValues.cargo,
        T_CARGO: formValues.tCargo,
        MOD_CONTRATO: formValues.modContrato,
        INSTITUCION: formValues.institucion,
        GENERO: formValues.genero,
        ID_PAIS: formValues.pais || "0",
        ID_EVENTO: formValues.idEvento,
        RESPUESTA: dayjs(formValues.respuesta || new Date()).format("DD/MM/YYYY")
      };

      console.log(formData);

      setRegistrando(true);
      fetch(`${URL_API}/guest/register`, {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data: iResponse) => {
          Swal.fire({
            icon: data.icon as SweetAlertIcon,
            text: data.text,
            confirmButtonText: 'De acuerdo',
            backdrop: true,
            allowOutsideClick: false,
            customClass: {
              container: 'my-swal-container'
            },
          }).then(() => {
            if (data.statusCode === "201" || data.statusCode === "202") {
              setFormValues({
                ...formValues,
                documento: '',
                nombres: '',
                apPaterno: '',
                apMaterno: '',
                celular: '',
                correo: '',
                cargo: '',
                tCargo: '',
                modContrato: '',
                institucion: '',
                genero: '',
                pais: '',
                permiso: false
              });

              navigate(`${folderDefault}/event/list`);
            }
            else if (data.statusCode === "200") {
              if (data.text.includes("RESPUESTA INCORRECTA")) {
                setErrorSecurity("Fecha incorrecta");
              }
            }
          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setRegistrando(false);
        })
    }
  };

  const getPais = () => {
    fetch(`${URL_API}/country/active`, {
      method: "GET",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
    })
      .then((data) => data.ok && data.json())
      .then((data: iLPais[]) => {
        setLPais(data);
      });
  }

  useEffect(() => {
    if (typeGuest) {
      FormDataValid();
    }
  }, [typeGuest])

  useEffect(() => {
    if (formValues.documento.length > 0) {
      // setErrorDni((info && info.PREGUNTA && info.PREGUNTA.length > 0) || typeGuest === 2 ? "" : "No se encontró el DNI");
    }
  }, [info])

  useEffect(() => {
    setErrorSecurity("");
  }, [formValues.respuesta])


  useEffect(() => {
    if (evento) {
      getInfo();
    } else {
      navigate(`${folderDefault}/event/list`)
    }
  }, [])

  const card = (
    <Fragment>
      <CardContent>
        <Typography color="text.secondary">
          {
            "La información contenida en el formulario será utilizada exclusivamente para cualquier comunicación relacionada a la presente actividad académica."
          }
        </Typography>
      </CardContent>
      <CardActions>
        <FormControlLabel
          required
          control={
            <Checkbox
              required
              checked={formValues.permiso}
              onChange={handleCheckedChange}
              name="permiso" />
          }
          label={<Typography color="text.secondary">
            {
              "Estoy de acuerdo con el uso de mis datos con el fin de contacto del presente evento"
            }
          </Typography>}
        />
      </CardActions>
    </Fragment>
  );

  return (
    <div id="register-person">
      <Container maxWidth={"lg"} id='register-guest'>
        <form method="post" onSubmit={(e) => {
          e.preventDefault();
          registrarEvento();
        }}>
          <h4>Registro al evento:</h4>
          <h1>{`${formValues.nomEvento}`}</h1>
          <FormControl className="data-complete" fullWidth>
            <div className="type-person">
              <Tabs value={typeGuest} onChange={handleTabChange} aria-label="icon label tabs">
                {
                  tabsList.map((item, index) =>
                    <Tab key={index} icon={item.icon} label={item.title} />
                  )
                }
              </Tabs>
            </div>
            <TextField
              required
              name="documento"
              label={`${typeGuest === 2 ? "Documento" : "DNI"}`}
              type={typeGuest === 2 ? "text" : "number"}
              placeholder='Ejemplo: "12345678"'
              value={formValues.documento}
              autoComplete="off"
              inputProps={{ maxLength: typeGuest === 2 ? 20 : 8 }}
              onChange={handleInputChange}
            />
            {errorDNI && (
              <p style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}>
                {errorDNI}
              </p>
            )}
            {
              info && info.NOMBRES !== "" &&
              <>
                <h2>{`¡Bienvenid@ ${info.NOMBRES}!`}</h2>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale='es'>
                    <DatePicker
                      label={info.PREGUNTA}
                      format="DD/MM/YYYY"
                      localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                      onChange={(val) => {
                        handlePickerDateChange("respuesta", val);
                      }}
                      openTo="year"
                      views={['year', 'month', 'day']}
                      value={formValues.respuesta !== "" ? formValues.respuesta : null}
                      slotProps={{
                        textField: {
                          required: true,
                        }
                      }}

                    />
                    {errorSecurity && (
                      <p style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}>
                        {errorSecurity}
                      </p>
                    )}
                  </LocalizationProvider>
                </FormControl>
              </>
            }

            {
              // tabsList[typeGuest].value === "extranjero" &&
              <>
                <TextField
                  required
                  name="nombres"
                  label="Nombres"
                  placeholder='Ingresa tus nombres'
                  value={formValues.nombres}
                  inputMode="text"
                  autoComplete="off"
                  onChange={handleInputChange}
                />
                <TextField
                  required
                  name="apPaterno"
                  label="A. paterno"
                  placeholder='Ingresa tu apellido paterno'
                  value={formValues.apPaterno}
                  inputMode="text"
                  autoComplete="off"
                  onChange={handleInputChange}
                />
                <TextField
                  required
                  name="apMaterno"
                  label="A. materno"
                  placeholder='Ingresa tu apellido materno'
                  value={formValues.apMaterno}
                  inputMode="text"
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </>
            }
            <TextField
              required
              name="celular"
              label="Celular"
              placeholder={`Ejemplo: "${typeGuest !== 0 ? "+51 " : ''}987654321"`}
              value={formValues.celular}
              inputMode="numeric"
              autoComplete="off"
              onChange={handleInputChange}
            />
            <TextField
              required
              name="correo"
              label="Correo"
              placeholder='Ejemplo: "example@empresa.com"'
              value={formValues.correo}
              type='email'
              inputMode="email"
              autoComplete="off"
              onChange={handleInputChange}
            />
            <TextField
              required
              name="cargo"
              label="Cargo"
              placeholder='Ejemplo: "Abogado"'
              value={formValues.cargo}
              inputMode="text"
              autoComplete="off"
              onChange={handleInputChange}
            />
            {
              tabsList[typeGuest].value === "personal" &&
              <>
                <FormControl fullWidth>
                  <InputLabel>Tipo de cargo</InputLabel>
                  <Select
                    required
                    name="tCargo"
                    label="Tipo de cargo"
                    value={formValues.tCargo}
                    onChange={handleSelectChange}
                  >
                    {
                      list_tCargo.map((item, index) => (
                        <MenuItem key={index} value={item.code}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Modalidad de contrato</InputLabel>
                  <Select
                    required
                    name="modContrato"
                    label="Modalidad de contrato"
                    value={formValues.modContrato}
                    onChange={handleSelectChange}
                  >
                    {
                      list_mContrato.map((item, index) => (
                        <MenuItem key={index} value={item.code}>
                          {item.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </>

            }
            {
              tabsList[typeGuest].value !== "personal" ?
                <TextField
                  required
                  name="institucion"
                  label="Institución"
                  placeholder='Ejemplo: "Corte Superior de Justicia de Ica"'
                  value={formValues.institucion}
                  inputMode="text"
                  autoComplete="off"
                  onChange={handleInputChange}
                /> :
                <Autocomplete
                  disablePortal
                  options={corteList}
                  onChange={(event: any, newValue: string | null) => {
                    setCortes(newValue);
                  }}
                  inputValue={formValues.institucion}
                  onInputChange={(event, newInputValue) => {
                    setFormValues({ ...formValues, institucion: newInputValue || "" });
                  }}
                  renderInput={(params) =>
                    <TextField {...params}
                      required
                      label="Institución" />}
                />
            }

            <FormControl fullWidth>
              <InputLabel>Género</InputLabel>
              <Select
                required
                name="genero"
                label="Género"
                value={formValues.genero}
                onChange={handleSelectChange}
              >
                {
                  list_genero.map((item, index) => (
                    <MenuItem key={index} value={item.code}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {
              tabsList[typeGuest].value === "extranjero" &&
              <FormControl fullWidth>
                <InputLabel>País</InputLabel>
                <Select
                  required
                  name="pais"
                  label="Pais"
                  value={formValues.pais}
                  onChange={handleSelectChange}
                >
                  {
                    lPais.map((item, index) => (
                      <MenuItem key={index} value={item.ID_PAIS}>
                        {item.NOM_PAIS}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            }

            <Card variant="outlined">{card}</Card>
            <Button
              type='submit'
              variant="contained"
              color="success"
              style={{ height: "100%" }}
              disabled={registrando}
            >
              REGISTRAR
            </Button>
          </FormControl>

        </form>
      </Container>
    </div>
  )
}

export default RegisterPerson