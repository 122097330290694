import { useEffect, useState, useRef, useMemo } from 'react';

import { URL_API, folderDefault } from "../../config";

import { getInfoUser } from "../../Tools/Auth";

import { iLEvento } from '../../iType';

// Iconos
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Checklist as ChecklistIcon,
  Settings as SettingsIcon
} from '@mui/icons-material';
import { faLink, faPersonChalkboard } from '@fortawesome/free-solid-svg-icons';

// Librerias
import dayjs from 'dayjs'; // Formato de fechas
import Swal from 'sweetalert2'; // Alertas

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Componentes
import { Tooltip } from '@mui/material'; // MUI

// Rutas
import { useNavigate } from 'react-router-dom';

import withReactContent from 'sweetalert2-react-content';
import ListasAsistencia from '../../Emergentes/ListasAsistencia';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// Tabla AgGridReact
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import AG_GRID_LOCALE_ES from '../../assets/localeText/AG_GRID_LOCALE_ES';

function MyEvents() {
  const navigate = useNavigate();

  const gridRef = useRef<AgGridReact>(null);

  const [lEventos, setLEventos] = useState<iLEvento[]>();

  const listaTtipoEventos = () => {
    fetch(`${URL_API}/evento/my`, {
      method: "POST",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({ idUsuario: getInfoUser()?.ID_USUARIO, pageSize: 25 })
    })
      .then((data) => data.ok && data.json())
      .then((data: iLEvento[]) => {
        setLEventos(data);
      })
  };

  const columnDefs: ColDef[] =
    [
      {
        headerName: 'N°',
        valueGetter: 'node.rowIndex + 1',
        minWidth: 60,
        sortable: false,
        filter: false,
        headerCheckboxSelection: false,
        checkboxSelection: false,
        pinned: 'left',
        width: 60,
      },
      { field: "NOMBRE_EVENTO", headerName: 'NOMBRE', minWidth: 300 },
      { field: "NOM_TEVENTO", headerName: 'T. EVENTO', minWidth: 300 },
      {
        field: "FINICIO", headerName: 'F. INICIO', minWidth: 200,
        cellRenderer: (cell: any, data: any, column: any) => {
          return dayjs(cell.value).format("DD/MM/YYYY hh:mm A");
        }
      },
      {
        field: "FFINAL", headerName: 'F. FINAL', minWidth: 200,
        cellRenderer: (cell: any, data: any, column: any) => {
          return dayjs(cell.value).format("DD/MM/YYYY hh:mm A");
        }
      },
      {
        field: "FINICIO_REG", headerName: 'F. INICIO - REGISTRO', minWidth: 200,
        cellRenderer: (cell: any, data: any, column: any) => {
          return dayjs(cell.value).format("DD/MM/YYYY hh:mm A");
        }
      },
      {
        field: "FFINAL_REG", headerName: 'F. FINAL - REGISTRO', minWidth: 200,
        cellRenderer: (cell: any, data: any, column: any) => {
          return dayjs(cell.value).format("DD/MM/YYYY hh:mm A");
        }
      },
      { field: "RESOLUCION", headerName: 'RESOLUCION', minWidth: 150 },
      {
        field: "FRESOLUCION", headerName: 'F. RESOLUCION', minWidth: 200,
        cellRenderer: (cell: any, data: any, column: any) => {
          return dayjs(cell.value).format("DD/MM/YYYY hh:mm A");
        }
      },
      { field: "ORGANIZADOR", headerName: 'ORGANIZADOR', minWidth: 400 },
      { field: "REALIZADOEN", headerName: 'REALIZADO EN', minWidth: 400 },
      { field: "ENLACE", headerName: 'ENLACE', minWidth: 300 },
      { field: "MENSAJE", headerName: 'MENSAJE', minWidth: 700 },
      {
        field: 'actionField',
        headerName: 'ACCIONES',
        minWidth: 300,
        cellRenderer: (params: any) => {
          const data: iLEvento = params.data;
          return (
            <>
              <ToggleButtonGroup
                size='small'
                color='primary'
                aria-label="Herramientas"
              >
                {/* Editar evento */}
                <ToggleButton
                  value="edit"
                  aria-label="Editar evento"
                  size='small'
                  disabled={data.ESTADO === "0"}
                  onClick={() => {
                    navigate(`${folderDefault}/event/create/`,
                      {
                        state: {
                          ID_EVENTO: data.ID_EVENTO
                        }
                      })
                  }}
                >
                  <Tooltip title="Editar evento" placement="bottom">
                    <EditIcon />
                  </Tooltip>
                </ToggleButton>

                {/* Ajuste de asistencia */}
                <ToggleButton
                  value="setting"
                  aria-label="Ajustes de asistencia"
                  disabled={data.ESTADO === "0"}
                  onClick={() => {
                    navigate(`${folderDefault}/event/gCode`, {
                      state: {
                        ID_EVENTO: data.ID_EVENTO,
                        NOMBRE_EVENTO: data.NOMBRE_EVENTO,
                        FINICIO: data.FINICIO,
                        FFINAL: data.FFINAL
                      }
                    })
                  }}
                >
                  <Tooltip title="Ajustes de asistencia" placement="bottom">
                    <SettingsIcon />
                  </Tooltip>
                </ToggleButton>

                {/* Enlace de asistencia */}
                {/* <ToggleButton
                  value="linked"
                  aria-label="centered"
                  size='small'
                  disabled={data.ESTADO === "0"}
                  onClick={() => {
                    navigate(`${folderDefault}/event/asistencias/${data.ID_EVENTO}`)
                  }}
                >
                  <Tooltip title="Enlace de asistencia" placement="bottom"><FontAwesomeIcon icon={faLink} /></Tooltip>
                </ToggleButton> */}

                {/* Enlace de asistencia */}
                <ToggleButton
                  value="linked"
                  aria-label="centered"
                  size='small'
                  disabled={data.ESTADO === "0"}
                  onClick={() => {
                    const MySwal = withReactContent(Swal);
                    MySwal.fire({
                      title: "Seleccione lista",
                      showConfirmButton: false,
                      html:
                        <>
                          <ListasAsistencia
                            info={{
                              ID_EVENTO: data.ID_EVENTO.toString(),
                              NOMBRE_EVENTO: data.NOMBRE_EVENTO
                            }}
                            onClose={() => {
                              MySwal.close();
                            }}
                            goTo={(ID_GCODE) => {
                              MySwal.close();
                              navigate(`${folderDefault}/event/asistencias/${data.ID_EVENTO}`, {
                                state: {
                                  ID_EVENTO: data.ID_EVENTO,
                                  NOMBRE_EVENTO: data.NOMBRE_EVENTO,
                                  ID_GCODE: ID_GCODE
                                }
                              })
                            }}
                          />
                        </>,
                      backdrop: true,
                      allowOutsideClick: false,
                      customClass: {
                        container: 'my-swal-container'
                      },
                    })
                  }}
                >
                  <Tooltip title="Enlace de asistencia" placement="bottom"><FontAwesomeIcon icon={faLink} /></Tooltip>
                </ToggleButton>

                {/* Lista de Registro */}
                <ToggleButton
                  value="listRegistro"
                  aria-label="Lista de registro"
                  size='small'
                  disabled={data.ESTADO === "0"}
                  onClick={() => {
                    navigate(`${folderDefault}/event/listRegistro`, {
                      state: {
                        ID_EVENTO: data.ID_EVENTO,
                        NOMBRE_EVENTO: data.NOMBRE_EVENTO,
                        FINICIO: data.FINICIO
                      }
                    })
                  }}
                >
                  <Tooltip title="Lista de registro" placement="bottom">
                    <ChecklistIcon />
                  </Tooltip>
                </ToggleButton>

                {/* Lista de asistencia */}
                <ToggleButton
                  value="justify"
                  aria-label="Lista de asistencia"
                  size='small'
                  disabled={data.ESTADO === "0"}
                  onClick={() => {
                    const MySwal = withReactContent(Swal);
                    MySwal.fire({
                      title: "Seleccione lista",
                      showConfirmButton: false,
                      html:
                        <>
                          <ListasAsistencia
                            info={{
                              ID_EVENTO: data.ID_EVENTO.toString(),
                              NOMBRE_EVENTO: data.NOMBRE_EVENTO
                            }}
                            onClose={() => {
                              MySwal.close();
                            }}
                            goTo={(ID_GCODE) => {
                              MySwal.close();
                              navigate(`${folderDefault}/asistencia/lista`, {
                                state: {
                                  ID_EVENTO: data.ID_EVENTO,
                                  NOMBRE_EVENTO: data.NOMBRE_EVENTO,
                                  ID_GCODE: ID_GCODE
                                }
                              })
                            }}
                          />
                        </>,
                      backdrop: true,
                      allowOutsideClick: false,
                      customClass: {
                        container: 'my-swal-container'
                      },
                    })
                  }}
                >
                  <Tooltip title="Lista de asistencia" placement="bottom">
                    <FontAwesomeIcon icon={faPersonChalkboard} />
                  </Tooltip>
                </ToggleButton>

                {/* Eliminar evento */}
                <ToggleButton
                  value="justify"
                  aria-label="Eliminar evento"
                  disabled={data.ESTADO === "0"}
                  onClick={() => {
                    handleDeleteClick(data.ID_EVENTO);
                  }}
                >
                  <Tooltip title="Eliminar evento" placement="bottom">
                    <DeleteIcon />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </>
          );
        }
      },
    ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      // minWidth: 180,
      filter: true,
      // floatingFilter: true,
      suppressSizeToFit: false,
      sortable: true,
      resizable: true,
      editable: false,
      cellStyle: { textAlign: 'center', verticalAlign: 'middle' }
    };
  }, []);

  const rowClassRules = useMemo(() => {
    return {
      // row style function
      'row-deleted': (params: any) => {
        return params.data.ESTADO === "0";
      },
    };
  }, []);

  const handleDeleteClick = (id: string) => {
    Swal.fire({
      icon: 'question',
      title: 'MENSAJE DEL SISTEMA',
      text: '¿Seguro que desea eliminar el siguiente elemento',
      confirmButtonText: 'Sí',
      showCancelButton: true,
      showDenyButton: true,
      backdrop: true,
      allowOutsideClick: false,
      customClass: {
        container: 'my-swal-container'
      },
    })
      .then((resp) => {
        if (resp.isConfirmed) {
          fetch(`${URL_API}/evento/remove`, {
            method: "POST",
            headers: {
              "content-type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({ idEvento: id })
          })
            .then((resp) => resp.ok)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "MENSAJE DEL SISTEMA",
                text: "¡Se ha elimando con éxito!",
                backdrop: true,
                allowOutsideClick: false,
                customClass: {
                  container: 'my-swal-container'
                },
              })
                .finally(() => {
                  listaTtipoEventos();
                })
            })
        }
      })
  };

  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_ES;
  }, []);

  useEffect(() => {
    listaTtipoEventos();
  }, [])

  return (
    <div id="my-events">
      <div className="ag-theme-alpine" >
        <AgGridReact
          className="tableEvents"
          ref={gridRef}
          rowData={lEventos}
          columnDefs={columnDefs}
          animateRows={true}
          rowClassRules={rowClassRules}
          // rowSelection='multiple'
          suppressRowClickSelection={true}
          suppressAggFuncInHeader={true}
          pagination={true}
          paginationAutoPageSize={true}
          paginateChildRows={true}
          floatingFiltersHeight={50}
          defaultColDef={defaultColDef}
          localeText={localeText}
        />
      </div>
    </div>
  )
}

export default MyEvents;