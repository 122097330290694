import { useState, useEffect } from "react";
import { URL_API } from "../../config";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { Button, TextField } from "@mui/material";
import { getEdadString, transformDate } from "../../Tools/DateTime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { iResponse } from "../../iType";
import dayjs from "dayjs";

interface iPersona {
  DOC: string,
  NOMBRES: string,
  APPATERNO: string,
  APMATERNO: string,
  FNACIMIENTO: string,
  DIRECCION: string,
  FOTO: string
}

interface iPrivilegio {
  ID_PRIVILEGIO: string,
  NOMBRE_PRIVILEGIO: string
}

const dataReset = {
  idUsuario: '0',
  newPass: '',
  idPrivilegio: '',
  doc: '',
  nombres: '',
  apPaterno: '',
  apMaterno: '',
  direccion: '',
  fnacimiento: '',
  foto: '',
  repeatPass: ''
};

const CreateUser = () => {
  // Guarda los valores de la persona
  const [values, setValues] = useState(dataReset);

  // |||| ESTADOS TEMPORALES   |||
  const [doc, setDoc] = useState("");
  const [error, setError] = useState(false);

  // ||| LISTAS TEMPORALES ||||
  const [privilegio, setPrivilegio] = useState<iPrivilegio[]>();


  // |||| CONSULTAS A DB |||||
  const getPrivilegio = () => {
    fetch(`${URL_API}/privilegio/all`)
      .then((resp) => resp.json())
      .then((data: iPrivilegio[]) => {
        if (data && data.length > 0 && data[0] !== null) {
          setPrivilegio(data);
        }
      })
  }

  const getPersonInfo = () => {
    if (doc !== values.doc) {
      fetch(`${URL_API}/usuario/exists`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify({ doc: doc })
        })
        .then((resp) => resp.json())
        .then((data: { EXISTENCIA: string }[]) => {
          if (data && data.length > 0 && data[0] !== null && data[0].EXISTENCIA === "1") {
            Swal.fire({
              icon: "warning",
              title: "MENSAJE DEL SISTEMA",
              text: "YA EXISTE EL USUARIO",
              backdrop: true,
              allowOutsideClick: false,
              customClass: {
                container: 'my-swal-container'
              },
            }).then((resp) => {
              if (resp.isConfirmed) {
                setDoc("");
                setValues(dataReset);
              }
            })
          } else {
            fetch(`${URL_API}/persona/get`,
              {
                method: "POST",
                headers: {
                  "content-type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify({ doc: doc })
              })
              .then((resp) => resp.json())
              .then((data: iPersona[]) => {

                if (data && data.length > 0 && data[0].NOMBRES) {
                  setValues({
                    ...values,
                    doc: data[0].DOC,
                    nombres: data[0].NOMBRES,
                    apPaterno: data[0].APPATERNO,
                    apMaterno: data[0].APMATERNO,
                    fnacimiento: transformDate(data[0].FNACIMIENTO),
                    direccion: data[0].DIRECCION,
                    foto: data[0].FOTO
                  })
                } else {
                  Swal.fire({
                    icon: "warning",
                    title: "MENSAJE DEL SISTEMA",
                    text: "¡La persona no existe!",
                    backdrop: true,
                    allowOutsideClick: false,
                    customClass: {
                      container: 'my-swal-container'
                    },
                  })
                }
              })
          }
        })
    }
  }

  // |||| ENVIO DE DATA |||||
  const SaveChanged = () => {
    fetch(`${URL_API}/usuario/create`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(values)
      })
      .then((resp) => resp.json())
      .then((data: iResponse) => {
        Swal.fire({
          icon: data.icon as SweetAlertIcon,
          title: data.title,
          text: data.text,
          backdrop: true,
          allowOutsideClick: false,
          customClass: {
            container: 'my-swal-container'
          },
        })
        if (data.statusCode === "201" || data.statusCode === "202") { setValues(dataReset); setDoc("") };
      })
  }

  //  |||| HANDLERS |||||
  const handleSelectChange = (event: SelectChangeEvent) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })
  };

  // Para comparar contrenias
  const handleInputPassChange = async (event: React.ChangeEvent<any>) => {
    setError(event.target.value !== values[event.target.name === "newPass" ? "repeatPass" : "newPass"]);

    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  }

  // ||| INICIALIZADORES  ||||||
  useEffect(() => {
    if (values && values.nombres !== '') {
      getPrivilegio();
    }
  }, [values])

  //  |||| COMPONENRT RETURN |||||
  return (
    <div id="userCreator">
      <form method="POST" onSubmit={(e) => { getPersonInfo(); e.preventDefault(); }}>
        <TextField
          required
          name="doc"
          label="Documento"
          placeholder='Ejemplo: "12345678"'
          value={doc}
          inputProps={{ maxLength: 8 }}
          autoComplete="off"
          onChange={(event) => setDoc(event.target.value)}
        />
        <Button
          type="submit"
          variant="outlined"
          startIcon={<FontAwesomeIcon
            icon={faSearch} />}>
          BUSCAR
        </Button>
      </form>
      <div className="container">
        <div className="boxFormCreator">
          <div className="boxData">
            <div className="boxResponsive">
              {
                values.nombres !== "" && <div className="boxDataPersona">
                  <div className="fotoPersona">
                    <img src={`data:image/png;base64,${values.foto}`} alt={`${values.nombres}`} />
                  </div>
                  <div className="detallePersona">
                    <h4>{values.doc}</h4>
                    <h2>{values.nombres}</h2>
                    <h3>{`${values.apPaterno} ${values.apMaterno}`}</h3>
                    <h4>{dayjs(values.fnacimiento).format("DD/MM/YYYY")}</h4>
                    <h4>{getEdadString(dayjs(values.fnacimiento).format("DD/MM/YYYY"))}</h4>
                    <h4>{values.direccion}</h4>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="boxFormField">
          {
            values.nombres !== "" && <form id="frmDataUser" method="POST" onSubmit={(e) => { SaveChanged(); e.preventDefault(); }}>
              <FormControl required sx={{ minWidth: 120 }}>
                <InputLabel id="idPrivilegio">Privilegio</InputLabel>
                <Select
                  labelId="idPrivilegio"
                  id="idPrivilegio"
                  name="idPrivilegio"
                  value={values.idPrivilegio}
                  label="Privilegio"
                  onChange={handleSelectChange}
                >
                  {
                    privilegio && privilegio.map((item, index) => (
                      <MenuItem key={index} value={item.ID_PRIVILEGIO}>{item.NOMBRE_PRIVILEGIO}</MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{!values.idPrivilegio ? `Seleccione un privilegio` : ' '}</FormHelperText>
              </FormControl>
              <TextField
                required
                type="password"
                name="newPass"
                label="Nueva contraseña"
                placeholder='Ingrese nueva contraseña'
                value={values.newPass}
                autoComplete="off"
                inputProps={{ maxLength: 16 }}
                onChange={handleInputPassChange}
                helperText={values.newPass.length === 0 ? 'Debe escribir una nueva contraseña' : ' '}
              />
              <TextField
                required
                name="repeatPass"
                label="Repita contraseña"
                placeholder='Repite contraseña'
                value={values.repeatPass}
                inputProps={{ maxLength: 16 }}
                onChange={handleInputPassChange}
                error={error}
                type="password"
                autoComplete="off"
                helperText={error ? 'Las contraseñas no coinciden' : ' '}
              />
              <Button
                type="submit"
                variant="contained"
                color="success"
                style={{ maxWidth: 250, fontWeight: "bold", margin: "auto" }}
              >
                REGISTRAR
              </Button>
            </form>
          }
        </div>
      </div>
    </div>
  )
}

export default CreateUser;