import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';
import { folderDefault } from '../config';
import { allowPermission, getInfoUser } from '../Tools/Auth';
interface iListEvent {
  ID_EVENTO: string;
  FONDO: string;
  NOMBRE_EVENTO: string;
  NOMBRE_TIPOEVENTO: string;
  PONENTE: string;
  FINICIO: string;
  FFINAL: string;
  RESOLUCION: string;
  ORGANIZADOR: string;
  REALIZADOEN: string;
  ENLACE: string,
  ID_USUARIO: string
}

interface Props {
  item: iListEvent,
  onDirect: (response: { url: string, state?: any }) => void
}

const ViewEvento: FC<Props> = ({ item, onDirect }) => {
  return (
    <Card variant="outlined">
      <div className="content-image">
        {
          item.FONDO ? <img src={item.FONDO} alt='' onClick={() => {
            let newWindow = window.open('', '_blank');
            newWindow && newWindow.document.write(`
              <head>
                <title>${item.NOMBRE_EVENTO}</title>
              </head>
              <body>
                <img 
                  src="${item.FONDO}" 
                  alt="Imagen en base64" 
                  style="width: 100%; height: 100%; object-fit: contain" 
                />
              </body>  
              `);
          }} />
            : <FontAwesomeIcon icon={faBan} size="5x" />
        }
      </div>
      <CardContent className='content-card'>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          textAlign={"center"}
        >
          {item.NOMBRE_EVENTO}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Día:{" "}
          <span>
            {dayjs(item.FINICIO).format(
              "dddd DD [de] MMMM [de] YYYY"
            )}
          </span>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Hora: <span>{dayjs(item.FINICIO).format("hh:mm A")}</span>
        </Typography>
        <br />
        <Typography variant="body2" color="text.secondary">
          {`Pontente(s): ${item.PONENTE}`}
        </Typography>
        <br />
        <Typography variant="body2" color="text.secondary">
          {`Organizador: ${item.ORGANIZADOR}`}
        </Typography>
        <br />
        <Typography variant="body2" color="text.secondary">
          {`A realizarse en: ${item.REALIZADOEN}`}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" variant='contained' onClick={() => {
          onDirect({
            url: `${folderDefault}/event/register/${item.ID_EVENTO}`,
          })
        }}>
          REGISTRARSE
        </Button>
        {/* <Button size="small" color="primary" variant='contained' onClick={() => {
          onDirect({
            url: `${folderDefault}/event/asistencia/${item.ID_EVENTO}`
          })
        }}>
          ASISTENCIA
        </Button> */}
        {
          (allowPermission(["ADMINISTRADOR"]) || 
          item.ID_USUARIO === getInfoUser()?.ID_USUARIO) &&
           <Button size="small" color="primary"
            variant='contained'
            onClick={() => {
              onDirect({
                url: `${folderDefault}/event/listRegistro`,
                state: {
                  state: {
                    ID_EVENTO: item.ID_EVENTO,
                    NOMBRE_EVENTO: item.NOMBRE_EVENTO,
                    FINICIO: dayjs(item.FINICIO).format("DD/MM/YYYY hh:mm A")
                  }
                }
              })
            }}>
            LISTA
          </Button>
        }
      </CardActions>
    </Card>
  )
}

export default ViewEvento;