import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { URL_API, folderDefault } from "../../config";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { iResponse, iLTEvento, iUsuario, iLEvento } from "../../iType";
import { useNavigate } from "react-router-dom";
import { FormControlLabel, FormLabel, Paper, Radio, RadioGroup } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useLocation } from "react-router-dom";
import { getInfoUser } from "../../Tools/Auth";

/**
 * Returns a file in Base64URL format.
 * @param {File} file
 * @return {Promise<string>}
 */
async function encodeFileAsBase64URL(file: any) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", () => {
      resolve(reader.result);
    });
    file && reader.readAsDataURL(file);
  });
}

interface iFormValues {
  idEvento: string,
  fondo: string,
  typeEvent: string,
  nameEvent: string,
  fInicio: dayjs.Dayjs | null,
  fFinal: dayjs.Dayjs | null,
  fInicioReg: dayjs.Dayjs | null,
  fFinalReg: dayjs.Dayjs | null,
  resolucion: string,
  fResolucion: dayjs.Dayjs | null,
  organizador: string,
  ponente: string,
  realizado: string,
  enlace: string,
  mensaje: string
}

export default function CreateEvent() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const user: iUsuario | null = getInfoUser();
  const [lTEvento, setLTEvento] = useState<iLTEvento[]>([]);
  const [values, setValues] = useState<iFormValues>({
    idEvento: "0",
    fondo: "",
    typeEvent: "",
    nameEvent: "",
    fInicio: null,
    fFinal: null,
    fInicioReg: null,
    fFinalReg: null,
    resolucion: "",
    fResolucion: null,
    organizador: "",
    ponente: "",
    realizado: "",
    enlace: "",
    mensaje: "Usted a sido registrado correctamente"
  });

  const [fCorreo, setFCorreo] = useState("MANUAL");

  const handleInputChange = (event: React.ChangeEvent<any>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handlePickerDateChange = (name: string, date: dayjs.Dayjs | null) => {
    setValues({
      ...values,
      [name]: date,
    });
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    setValues({
      ...values,
      [event.target.name]: file && (await encodeFileAsBase64URL(file)),
    });
  };

  const listaTEventos = async () => {
    try {
      const response = await fetch(`${URL_API}/tevent/active`, {
        method: "GET",
        headers: {
          "content-type": "application/json;charset=UTF-8",
        },
      });

      if (response.ok) {
        const data = await response.json();
        setLTEvento(() => data);

        if (state && state.ID_EVENTO) {
          loadEvent(data);
        }
      } else {
        // Manejar el caso en el que la respuesta no sea exitosa
        console.error("Error en la respuesta de la consulta.");
      }
    } catch (error) {
      // Manejar errores de red u otros errores
      console.error("Error en la consulta:", error);
    }
  }

  const loadEvent = async (tEventos: iLTEvento[]) => {
    await fetch(`${URL_API}/evento/consulta`, {
      method: "POST",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        ID_EVENTO: state.ID_EVENTO,
        ID_USUARIO: getInfoUser()?.ID_USUARIO
      }),
    })
      .then((data) => {
        if (!data.ok) {
          throw new Error(`HTTP error! Status: ${data.status}`);
        }
        return data.json()
      })
      .then((data: iLEvento[]) => {
        if (data.length > 0) {
          const evento = data[0];
          const tEvento = tEventos.filter((item) => item.ID_TEVENTO === evento.ID_TEVENTO)[0]?.ID_TEVENTO;
          setValues(() => ({
            idEvento: state.ID_EVENTO,
            fondo: evento.FONDO,
            typeEvent: tEvento,
            nameEvent: evento.NOMBRE_EVENTO,
            ponente: evento.PONENTE,
            fInicio: dayjs(evento.FINICIO),
            fFinal: dayjs(evento.FFINAL),
            fInicioReg: dayjs(evento.FINICIO_REG),
            fFinalReg: dayjs(evento.FFINAL_REG),
            resolucion: evento.RESOLUCION,
            fResolucion: dayjs(evento.FRESOLUCION),
            organizador: evento.ORGANIZADOR,
            realizado: evento.REALIZADOEN,
            enlace: evento.ENLACE,
            mensaje: evento.MENSAJE,
          }));
          setFCorreo(() => evento.AUTOMAIL === "0" ? "MANUAL" : "AUTOMATICO");
        }

      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Ha ocurrido un error",
          text: err,
          backdrop: true,
          allowOutsideClick: false,
          customClass: {
            container: 'my-swal-container'
          },
        });
      });
  }

  const crearEvento = () => {
    fetch(`${URL_API}/evento/create`, {
      method: "POST",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        ...values,
        fInicio: dayjs(values.fInicio).format("YYYY/MM/DD HH:mm"),
        fFinal: dayjs(values.fFinal).format("YYYY/MM/DD HH:mm"),
        fInicioReg: dayjs(values.fInicioReg).format("YYYY/MM/DD HH:mm"),
        fFinalReg: dayjs(values.fFinalReg).format("YYYY/MM/DD HH:mm"),
        fResolucion: dayjs(values.fResolucion).format("YYYY/MM/DD HH:mm"),
        idUsuario: user?.ID_USUARIO,
        automail: (fCorreo === "AUTOMATICO" ? "1" : "0")
      }),
    })
      .then((data) => {
        if (!data.ok) {
          throw new Error(`HTTP error! Status: ${data.status}`);
        }
        return data.json()
      })
      .then((data: iResponse) => {
        Swal.fire({
          icon: data.icon as SweetAlertIcon,
          text: data.text,
          backdrop: true,
          allowOutsideClick: false,
          customClass: {
            container: 'my-swal-container'
          },
        }).then(() => {
          if (data.statusCode === "201" || data.statusCode === "202") {
            setValues({
              idEvento: "0",
              fondo: "",
              typeEvent: "",
              nameEvent: "",
              fInicio: null,
              fFinal: null,
              fInicioReg: null,
              fFinalReg: null,
              resolucion: "",
              fResolucion: null,
              organizador: "",
              ponente: "",
              realizado: "",
              enlace: "",
              mensaje: ""
            });
            navigate(`${folderDefault}/event/list`);
          }
        });
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    listaTEventos();
  }, []);

  return (
    <form
      id="create-event"
      method="POST"
      onSubmit={(e) => {
        crearEvento();
        e.preventDefault();
      }}
    >
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid xs={10} sm={10} lg={8}>
          <FormControl style={{ margin: 10 }} fullWidth>
            <input
              type="file"
              id="fondo"
              name="fondo"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <div
              onClick={() => {
                document.getElementById("fondo")?.click();
              }}
              style={{ cursor: "pointer" }}
            >
              <Paper
                style={{
                  margin: "auto",
                  maxWidth: "100%",
                  minWidth: "300px",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {values.fondo ? (
                  <img
                    style={{ maxWidth: "100%", objectFit: "contain" }}
                    src={values.fondo}
                    alt="No Found"
                    height={300}
                  />
                ) : (
                  <AddPhotoAlternateIcon fontSize={"large"} />
                )}
              </Paper>
            </div>
            <FormControl fullWidth>
              <InputLabel>Tipo de evento</InputLabel>
              <Select
                required
                name="typeEvent"
                label="Tipo de evento"
                value={values.typeEvent}
                onChange={handleSelectChange}
              >
                {lTEvento &&
                  lTEvento.map((item: iLTEvento, index: number) => (
                    <MenuItem key={index} value={item.ID_TEVENTO}>
                      {item.NOM_TEVENTO}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              required
              name="nameEvent"
              label="Nombre de evento"
              placeholder='Ejemplo: "PRECEDENTE JUDICIAL"'
              value={values.nameEvent}
              inputProps={{
                maxLength: 500
              }}
              onChange={handleInputChange}
              autoComplete="off"
            />
            <TextField
              required
              name="ponente"
              label="Ponente"
              multiline
              rows={4}
              placeholder='Ejemplo: "Dr. Juan Perez"'
              value={values.ponente}
              inputProps={{
                maxLength: 1000
              }}
              onChange={handleInputChange}
              autoComplete="off"
            />
            <Grid container spacing={2}>
              <Grid xs={12} md={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Fecha de inicio"
                      format="DD/MM/YYYY hh:mm A"
                      onChange={(val) => {
                        handlePickerDateChange("fInicio", val);
                      }}
                      value={values.fInicio}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Fecha final"
                      format="DD/MM/YYYY hh:mm A"
                      onChange={(val) => {
                        handlePickerDateChange("fFinal", val);
                      }}
                      value={values.fFinal}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={12} md={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Fecha de inicio registro"
                      format="DD/MM/YYYY hh:mm A"
                      onChange={(val) => {
                        handlePickerDateChange("fInicioReg", val);
                      }}
                      value={values.fInicioReg}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Fecha final registro"
                      format="DD/MM/YYYY hh:mm A"
                      onChange={(val) => {
                        handlePickerDateChange("fFinalReg", val);
                      }}
                      value={values.fFinalReg}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    name="resolucion"
                    label="Resolución de autorización"
                    placeholder='Ejemplo: "N° 123-2019-P-CSJICA-PJ"'
                    inputProps={{ maxLength: 100 }}
                    onChange={handleInputChange}
                    value={values.resolucion}
                    autoComplete="off"
                  />
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Fecha resolución"
                      format="DD/MM/YYYY"
                      onChange={(val) => {
                        handlePickerDateChange("fResolucion", val);
                      }}
                      value={values.fResolucion}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
            <TextField
              required
              name="organizador"
              label="Organizador"
              placeholder='Ejemplo: "Comisión General de Capacitación"'
              onChange={handleInputChange}
              inputProps={{
                maxLength: 300
              }}
              value={values.organizador}
              autoComplete="off"
            />
            <TextField
              required
              name="realizado"
              label="Realizado en"
              placeholder='Ejemplo: "Auditorio del Colegio de Abogados"'
              onChange={handleInputChange}
              inputProps={{
                maxLength: 200
              }}
              value={values.realizado}
              autoComplete="off"
            />
            <TextField
              required
              name="enlace"
              label="Enlace de la reunión"
              placeholder='Ejemplo: "https://meet.google.com/cod-igor-reu"'
              onChange={handleInputChange}
              inputProps={{
                maxLength: 500
              }}
              value={values.enlace}
              autoComplete="off"
            />
            <TextField
              required
              name="mensaje"
              label="Mensaje"
              placeholder="Mensaje que se mostrará al usuario al registrarse al evento"
              multiline
              rows={4}
              onChange={handleInputChange}
              value={values.mensaje}
              inputProps={{
                maxLength: 300
              }}
            />
            <Button
              type="button"
              variant="contained"
              color="warning"
              style={{ maxWidth: 250, fontWeight: "bold", }}
              onClick={() => {
                Swal.fire({
                  icon: "success",
                  text: `${values.mensaje}`,
                  confirmButtonText: 'De acuerdo',
                  backdrop: true,
                  allowOutsideClick: false,
                  customClass: {
                    container: 'my-swal-container'
                  },
                })
              }}
            >
              PROBAR MENSAJE
            </Button>
            <FormControl>
              <FormLabel id="cmbCorreoAuto">FORMA DE ENVIAR CORREO AL REGISTRAR: </FormLabel>
              <RadioGroup
                row
                aria-labelledby="cmbCorreoAuto"
                defaultValue="MANUAL"
                name="tCorreoAuto"
                onChange={(e, val) => {
                  setFCorreo(val)
                }}
              >
                <FormControlLabel value="AUTOMATICO" control={<Radio />} label="AUTOMÁTICO" />
                <FormControlLabel value="MANUAL" control={<Radio />} label="NO ENVIAR CORREO" />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <Button
                type="submit"
                variant="contained"
                color="success"
                style={{ maxWidth: 250, fontWeight: "bold", margin: "auto" }}
              >
                {
                  state && state.ID_EVENTO ?
                    "Editar" : "Registrar"
                }
              </Button>
            </FormControl>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}
